<template>
    <div class="container-banner">
        <div class="block" :class="{end:id>=list.length-1}">
            <div class="list" :style="{ transform:`translateX(-${x}px)` }">
                <div v-for="(item,i) in list" class="item">
                    <img :src="item" alt="" :ref="`img${i}`">
                </div>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
interface IData {
    id: number,
    list: Array<string>,
    x: number,
}

export default {
    data(): IData {
        return {
            id: 0,
            list: [],
            x: 0,
        }
    },
    mounted() {
        window.scrollTo(0, 0)

        this.list = new Array(78).fill({}).map((m, i) => {
            let id = (i + 1) * 3
            id = id > 222 ? 222 : id
            let num = id < 10 ? `00${id}` : id < 100 ? `0${id}` : id
            return `https://vertu-glass.oss-cn-hangzhou.aliyuncs.com/metaphone/1_00${num}.png?x-oss-process=image/format,webp`

        })

        let width = window.outerWidth;
        let len = this.list.length

        window.document.onscroll = ((e) => {
            let id = Math.floor(window.scrollY / 50)

            if (id > len - 1) {
                this.id = len - 1
            } else {
                this.id = id
            }
            width = this.$refs['img0'][0].width
            this.x = width * this.id

            // console.log('this.id', this.id, window.scrollY);
        })
    }
}
</script>

<style lang='less' scoped>
.container-banner {
    height: calc(100vh + 3700px);
    // height: 4837px;
    overflow: hidden;
    position: relative;
    background-color: black;

    .block {
        position: fixed;
        top: 105px;
        left: 0;
        right: 0;
        z-index: 20;
        // overflow: hidden;
        background-color: black;

        &.end {
            position: absolute;
            bottom: 0;
            top: auto;
        }

        canvas {
            width: 100%;
            height: 100%;
        }

        .list {
            height: 100vh;
            display: flex;
            flex-wrap: nowrap;

            .item {
                width: 100vw;
                height: auto;
                //height: 100vh;
                flex-shrink: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
</style>
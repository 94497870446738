import { createApp } from "vue";
import { createPinia } from "pinia";
import { IsMobile, AnimateDirective } from "@/plugin";
import "./style.less";
// import "amfe-flexible";
import Vant from "vant";
import "vant/lib/index.css";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "animate.css";

import App from "./App.vue";
import router from "./router";
import lang from "./language";

createApp(App)
    .use(lang)
    .use(IsMobile)
    .use(ElementPlus)
    .use(createPinia())
    .use(router)
    .use(Vant)
    .directive("animate", AnimateDirective)
    .mount("#app");

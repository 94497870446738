<template>
    <div class="video">
        <video class="index-video" poster="@images/meta/video-poster.png"  muted autoplay loop  webkit-playsinline='true'
               playsinline='true'>
            <source src="@/assets/meta/vvv.mp4" />
        </video>
    </div>
</template>

<style lang="less" scoped>
.video {
    width: 100%;
    height: 211px;

    //padding-top: 60px;
    video {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width:768px) {
    .video {
        height: 211px;
    }
}
</style>
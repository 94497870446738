export const TOKEN_KEY = "token";
export const USER_KEY = "user";
import { ICurrentUser } from "@/services/user";

export const setToken = (data: string) => {
    localStorage.setItem(TOKEN_KEY, data);
};

export const getToken = (): string => {
    return localStorage.getItem(TOKEN_KEY) || "";
};
export const clearToken = () => {
     localStorage.removeItem(TOKEN_KEY)
};

export const setUser = (data: ICurrentUser) => {
    localStorage.setItem(USER_KEY, JSON.stringify(data));
};
export const clearUser = () => {
    localStorage.removeItem(USER_KEY)
};

export const getUser = (): ICurrentUser => {
    const _u = localStorage.getItem(USER_KEY) || "{}";
    return JSON.parse(_u);
};

<template>
  <div class="pc" v-if="deviceName!=='phone'">
    <template v-if="appointment_data.length">
      <div class="item" v-for="(item, n) in appointment_data" :key="n">
        <video poster="" muted autoplay="autoplay" loop="loop" webkit-playsinline='true'
               playsinline='true'>
          <source :src="item.cover1"/>
        </video>
        <template v-if="locale === 'zh'">
          <p class="p1">{{ item.name }}</p>
          <p class="p2">{{ $t('user.collect.item.name') }}：{{ item.chain_name }}</p>
          <p class="p2">{{ $t('user.collect.item.hash') }}：{{ item.hash }}</p>
          <p class="p2">{{ $t('user.collect.item.block_hash') }}：{{ item.blockchain_hash }}</p>
        </template>
        <template v-else>
          <p class="p1">{{ item.name }}</p>
<!--          <span class="Freemint">Freemint</span>-->
        </template>
      </div>
    </template>
    <Empty v-else @click="toHome" :text="$t('user.collect.nodata1')"/>
  </div>
  <div class="collect" v-else-if="deviceName==='phone'">
    <template v-if="appointment_data.length">

      <div v-for="(item, n) in appointment_data" :key="n">
        <div class="item">
          <video poster="" muted autoplay="autoplay" loop="loop">
            <source :src="item.cover1"/>
          </video>
          <p class="p1">{{ item.name }}</p>
          <p class="p2">{{ $t('user.collect.chain_name') }}：{{ item.chain_name }}</p>
          <p class="p2">token ID：{{ item.hash }}</p>
          <p class="p2">{{ $t('user.collect.blockchain_hash') }}：{{ item.blockchain_hash }}</p>
        </div>
      </div>

      <div class="nodata" v-show="showPage" v-if="!appointment_data.length">
        <img src="@/assets/user/card.png" alt="" srcset="">
        <P>{{ $t('user.home.nodata1') }}</P>
<!--        <div class="btn" @click="toHome">{{ $t('user.now.book') }}</div>-->
      </div>
    </template>
    <Empty v-else @click="toHome" :text="$t('user.collect.nodata1')"/>
  </div>
</template>

<script>
import {giftList} from '@/services/person'
import router from '@/router';
import Empty from './empty.vue'
import {userStore} from "@/store";
import {locale} from "@/services"
import {deviceName} from '@/utils'
import {Toast} from "vant";

export default {
  data() {
    return {
      appointment_data: [], // 预约2,
      locale,
      deviceName,
    }
  },
  setup() {
    const store = userStore()
    return {
      store,
    }
  },
  components: {
    Empty
  },
  computed: {
    IsChina() {
      return this.$store.state.IsChina
    },
  },
  mounted() {
    giftList().then(res => {
      console.log('res', res);
      if (deviceName !== 'phone') this.appointment_data = res.data;
      else if (deviceName == 'phone') {
        Toast.clear();
        this.showPage = true;
        let list = [...res.data];
        this.appointment_data = list;
      }
    })
  },
  methods: {
    toHome() {
      router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
.pc {
  @media screen and (min-width:768px){
  .item {
    text-align: center;
    font-size: 11px;
    margin-bottom: 50px;
    video {
      width: 220px;
      // height: 140px;
    }
    .p1 {
      margin: 20px 0;
      font-size: 14px;
    }
    .Freemint {
      width: 9.8958vw;
      height: 3.0208vw;
      background: url('@/assets/user/bg-08.png') no-repeat;
      background-size: 100%;
      display: inline-block;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: .9375vw;
      color: #000000;
      text-align: center;
      line-height: 2.8646vw;
      cursor: pointer;
      background-size: 100%;
      &.on {
        cursor: no-drop;
        color: #999;
      }
    }
  }
}
}
@media screen and (max-width:768px) {
  .collect {
    width: 100%;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 50px;
    &>div{
      padding-bottom: 50px;
    }
    .item {
      text-align: center;
      font-size: 16px;
      padding-bottom: 20px;
      word-break: break-word;
      display: flex;
      flex-direction: column;
      img {
        // width: 220px;
        height: 140px;
      }
      video {
        width: 100%;
        //height: 390px;
      }
      .p1 {
        text-align: center;
        margin: 20px 0;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 16px;
      }
      .p2 {
        padding: 0 10px;
        box-sizing: border-box;
        word-break: break-all;
        font-size: 13px;
        text-align: center;
      }
    }
    .nodata {
      text-align: center;
      color: #fff;
      font-size: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      border: 1px dashed #555;
      width: 660px;
      height: 390px;
      margin: 0 auto;
      img {
        width: 100px;
      }
    }
    .btn {
      background: url("../../../assets/img/img-08.png") no-repeat;
      background-size: contain;
      width: 220px;
      text-align: center;
      color: #000;
      line-height: 64px;
    }
  }
}
</style>
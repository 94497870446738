<template>
  <div class="empty pc" :class="deviceName=='phone'?'phone':''">
    <img src="@/assets/user/no-1.png" alt="" v-if="type == 1"/>
    <img src="@/assets/user/no-2.png" alt="" v-else/>
    <p>{{ text }}</p>
    <!--    <div class="btn" @click="onClick">{{ $t('user.now.book') }}</div>-->
  </div>
</template>

<script>
import {deviceName} from '@/utils'

export default {
  data() {
    return {
      deviceName,
    }
  },
  props: ['type', 'text'],
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less" scoped>
.empty.pc {
  width: 425px;
  height: 255px;
  border: 1px dashed #fff;
  display: flex;
  font-size: 11px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  gap: 20px;
  img {
    width: 60px;
  }
  .btn {
    font-size: 9px;
    color: black;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: url('../../assets/user/button.png') no-repeat;
    background-size: cover;
  }
}
@media screen and (max-width: 768px) {
  .empty {
    &.phone {
      height: 155px;
      width: 100%;
      padding: 0;
      margin: 0 auto;
      font-size: 11px;
      img {
        width: 50px;
        margin-bottom: 10px;
      }
    }
    .pc {
      height: 390px;
    }
  }
}


</style>

import { DirectiveBinding } from "vue";

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $isMobile: boolean;
    }
}

export const IsMobile = {
    install: (app, options) => {
        app.config.globalProperties.$isMobile = !!navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
    },
};

// 所有
const animates: Animate[] = []
// 块的高度 = 整个屏幕的高度 分成4块
const blockH = window.innerHeight / 4

interface AnimateOptions {
    num: number
    isToggle: boolean
}

class Animate {
    // 出现在第几块
    private dom: Element
    private show?: boolean // 是否显示
    private Y: number = 0  // 进入和离开动画时的Y点
    private isToggle: boolean = true  // 离开可视区域时是否隐藏

    constructor(dom: Element, option: AnimateOptions) {
        this.dom = dom
        this.Y = blockH * (option.num - 1)
        this.isToggle = option.isToggle === undefined ? true : option.isToggle
        this.check()
    }
    check() {
        if (!this.dom) return
        let { y } = this.dom.getBoundingClientRect()
        if (y && y < this.Y && !this.show) {
            this.dom?.classList.replace('out', 'in')
            this.show = true
        } else if (y && y > this.Y && this.show && this.isToggle) {
            console.log('this.isToggle', this.isToggle)
            this.dom?.classList.replace('in', 'out')
            this.show = false
        }
    }
}

export const AnimateDirective = (el: Element, binding: DirectiveBinding) => {
    // console.log("errrrr", el, binding);
    el.classList.add('animate', 'out')
    animates.push(new Animate(el, { num: 4, ...binding.value }))
};

window.addEventListener('scroll', () => {
    animates.forEach(m => m.check())
})

export type TLocale = "zh" | "en";

export const locale: TLocale = navigator.language === "zh-CN" ? "zh" : "en";
export const Menus = ["Home", "METAVERTU", "Vpass"];
export const deviceName = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)
    ? "phone"
    : "pc";
export const isDev =
    location.pathname.substring(0, 5) === "/test" ||
    location.hostname === "localhost" ||
    location.hostname.startsWith("192.") ||
    location.hostname.startsWith("127.");
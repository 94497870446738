import { defineStore } from "pinia";
import {setToken, getToken, setUser, getUser, clearToken, clearUser} from "@/utils/storge";
import { getUserByToken, ICurrentUser } from "@/services/user";

interface IStateProps {
    currentUser?: ICurrentUser;
    token: string;
    loginDialog: boolean;
}

export const userStore = defineStore("main", {
    state: (): IStateProps => ({
        token: getToken(),
        currentUser: getUser(),
        loginDialog: false,
    }),
    getters: {},
    actions: {
        setLoginDialog(flag: boolean) {
            this.loginDialog = flag;
        },
        setToken(token: string) {
            this.token = token;
            setToken(token);
        },
        clearToken() {
            this.token = '';
            clearToken();
        },
        setCurrentUser(user: ICurrentUser) {
            this.currentUser = user;
            setUser(user);
        },
        async getCurrentUser(token: string) {
            this.setToken(token);
            this.fetchCurrentUser();
        },
        async clearCurrentUser() {
            this.clearToken();
            clearUser();
        },
        async fetchCurrentUser() {
            console.log('in fetch')
            if (this.token) {
                // console.log('getByToken')
                let { data = {} } = await getUserByToken();
                if (data.id) {
                    this.setCurrentUser(data);
                }
            }
        },
    },
});

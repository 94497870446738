<template>
  <Head :activeIndex="state.activeIndex"/>
  <swiper
      :direction="'vertical'"
      :slidesPerView="1"
      :spaceBetween="20"
      :effect="'fade'"
      :mousewheel="true"
      @slideChange="onSlideChange"
      :modules="state.modules"
      class="mySwiper"
  >
    <swiper-slide>
      <div class="vh100 page0" :class="{ 'page0-active': state.activeIndex === 0 }">
        <video class="index-video" poster="" muted autoplay="autoplay" loop="loop">
          <source src="@/assets/gif/background.mp4"/>
        </video>
        <div class="text-box">
          <p>{{$t('republicH5.technology')}}</p>
          <p class="small">DATA GOVERNANCE OF THE PEOPLE, BY THE PEOPLE, FOR THE PEOPLE</p>
        </div>
        <div class="footWord"> @2022-2023 VERTU ALL RIGHTS RESERVED <a href="http://beian.miit.gov.cn/"
            target="_blank">蜀ICP备20023191号</a> </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <MainInfo tab="first" :activeIndex="state.activeIndex"></MainInfo>
    </swiper-slide>
    <swiper-slide>
      <MainInfo tab="second" :activeIndex="state.activeIndex"></MainInfo>
    </swiper-slide>
    <swiper-slide>
      <MainInfo tab="third" :activeIndex="state.activeIndex"></MainInfo>
    </swiper-slide>
    <swiper-slide>
      <Foot :activeIndex="state.activeIndex"></Foot>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import {reactive} from 'vue'
import Head from './components/Head.vue'
import MainInfo from './components/MainInfo.vue'
import Foot from './components/Foot.vue'
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Mousewheel, EffectFade} from 'swiper';
import 'swiper/less';
import './index.less';
import "swiper/less/effect-fade";

const state = reactive({
  activeIndex: 0,
  modules: [Mousewheel, EffectFade]
})
const onSlideChange = (e) => {
  state.activeIndex = e.activeIndex;
};

</script>

<style lang="less" scoped>
@keyframes pageChange {
  from {
    opacity: 0;
  }
}

.swiper {
  height: 100%;
  width: 100%;
}

.vh100 {
  height: 100vh;
  background-color: #000;
  color: #fff;
  text-align: center;
  position: relative;
  .footWord {
      color: #FFFFFF99;
      font-family: Source Han Sans CN;
      font-size: 12px;
      font-weight: 350;
      line-height: 18px;
      letter-spacing: 0.08em;
      position: absolute;
      bottom: 4px;
      left: 0;
      right: 0;
      margin: auto;
      a {
        color: #FFFFFF99;
        text-decoration: none;
      }
    }
}

.page0 {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 100;

  &-active {
    img {
      animation: pageChange;
      animation-duration: 1s;
    }

    .text-box {
      font-family: 'Qurany-Bold';
      animation: pageChange;
      animation-duration: 3s;
    }
  }

  .text-box {
    font-family: 'Qurany-Bold';
    position: absolute;
    width: 100%;
    bottom: 170px;

  }

  p {
    text-align: center;
    font-size: 100px;
    color: #FFFFFF;
    letter-spacing: 5px;
    margin-bottom: 40px;
  }

  .small {
    letter-spacing: 2px;
    font-size: 30px;
    font-family: 'Montserrat-Light-6';
  }
}

</style>
<template>
    <MHeader v-if="$isMobile" />
    <Header :bg="true" v-else :activeIndex="1" />

    <div class="home">
        <MBanner v-if="$isMobile" />
        <Banner v-else />
        <Manifesto />
        <Video />
        <Design />
        <Recruit />
    </div>
    <Footer />
</template>

<script lang='ts'>
// import Header from './components/header.vue'
import Header from '@/pages/republic/pc/components/Head.vue'
import MHeader from '@/pages/republic/phone/components/Head.vue'

import Footer from './components/footer.vue'
import Banner from './home/banner.vue'
import MBanner from './home/Mbanner.vue'
import Manifesto from './home/manifesto.vue'
import Design from './home/design.vue'
import Recruit from './home/recruit.vue'
import Video from './home/video.vue'

import 'element-plus/theme-chalk/display.css'

export default {
    components: {
        Header, MHeader, Banner, MBanner, Manifesto, Video, Design, Recruit, Footer
    },
    data() {
        return {
        }
    },
    mounted() {
        scrollTo(0, 0);
    }
}
</script>

<style lang="less" scoped>
.home {
    background-color: rgb(13, 13, 13);
    // margin-top: 105px;
}

@media screen and (max-width:768px) {
    .home {
        margin-top: 60px;
    }
}
</style>
<template>
    <div class="video">
        <video v-if="locale==='zh'" class="index-video" poster="@images/meta/video-poster.png" muted autoplay loop webkit-playsinline='true'
               playsinline='true'>
            <source src="@/assets/meta/V8.mp4" />
        </video>
      <video v-else class="index-video" poster="@images/meta/video-poster.png" muted autoplay loop webkit-playsinline='true'
             playsinline='true'>
        <source src="@/assets/meta/V2-en.mp4" />
      </video>
    </div>
</template>

<script lang='ts'>
import {locale} from "@/services"

export default {
    data() {
        return {
          locale
        }
    },
    methods: {
        onPlay() {
            // console.log('aaa', this.$refs['v']);
        }
    }
}
</script>

<style lang='less' scoped>
.video {
    padding: 125px;
    background-color: rgb(13, 13, 13);
    display: flex;
    justify-content: center;

    video {
        cursor: pointer;
        width: 100vw;
        //width: 945px;
        //height: 550px;
        // background-color: rgb(217, 217, 217);
    }
}

@media screen and (max-width:768px) {
    .video {
        padding: 0 15px;

        video {
            width: 100%;
            height: 200px;
        }
    }
}
</style>
<template>
    <div class="Mheader">
        <div class="head">
            <img src="@/assets/h5/menu.png" alt="" @click="handleTab(!state.show)"/>
            <img src="@/assets/h5/logo.png" alt="" @click="goHome"/>
            <!-- <Login>
                <img src="@/assets/h5/login.png" alt="" />
            </Login> -->
            <img src="@/assets/h5/login.png" alt="" @click="loginDialog" v-if="!main.currentUser.id"/>
            <div class="avatar-box" v-if="main.currentUser.id"  @click="showMenu">
                <img :src="`https://republicofvertu.com${isDev ? '/test' : ''}` + main.currentUser.avatar" alt="avatar" class="avatar">
                <div class="user-nav" v-if="state.showMe">
                    <div class="user-nav-list" @click="goTo(0)">{{ $t('Head.nx8u7x') }}</div>
                    <div class="user-nav-list" @click="goTo(1)">{{ $t('Head.rzn6l7') }}</div>
                    <div class="user-nav-list" @click="goTo(2)">{{ $t('Head.jmizgm') }}</div>
                    <div class="user-nav-list" @click="logout">{{ $t('Head.w69zi8') }}</div>
                </div>
            </div>
        </div>
        <div :class="state.show?'jLAyPo':'kvNLLB'" >
            <div class="menu " :class="{active:(router.currentRoute.value.path == '/' || router.currentRoute.value.path == '/republic')}" @click="goHome">Home</div>
            <div class="menu" :class="{active:router.currentRoute.value.path == '/metavertu'}" @click="goMeta" >Metavertu</div>
            <div class="menu" @click="goVpass">VPass</div>
            <div class="menu" @click="goW">WHITEPAPER</div>
        </div>
        <div :class="state.show?'mask':'hide'" @click="handleTab(!state.show)" ></div>
    </div>
    <login/>
</template>
<script setup lang="ts">
    import {isDev, openToVpass} from "@/utils/index.js"
    import Login from '@/components/login2.vue'
    import {userStore} from '@/store/user.ts'
    import { reactive} from 'vue'
    import { useRouter } from 'vue-router'
    import {locale} from "@/services"
    const router = useRouter()
    const state = reactive({
        show:false,
        showMe: false
    })
    const handleTab = (val:any) => {
        state.show = val
        document.body.addEventListener('touchmove',bodyScroll,false);  
        if(val){
            document.body.style.overflow='hidden';    
        } else {
            document.body.style.overflow='';    
        }
          

    }
    function bodyScroll(event:any){  
        event.preventDefault();  
    } 

    const main = userStore()

    const loginDialog = () => {
        main.loginDialog = true;
        document.body.addEventListener('touchmove',bodyScroll,false);  
        document.body.style.overflow='hidden';    
    }
    const showMenu = () => {
        state.showMe = !state.showMe;
    }
    
    const goHome = ()=>{
        document.body.addEventListener('touchmove',bodyScroll,false);  
        document.body.style.overflow='';    
        router.push('/republic')
    }
    const goMeta = ()=>{
        document.body.addEventListener('touchmove',bodyScroll,false);  
        document.body.style.overflow='';  
        router.push('/metavertu')
    }
    const goVpass = ()=>{
        document.body.addEventListener('touchmove',bodyScroll,false);  
        document.body.style.overflow='';
        openToVpass();
    }
    function goTo(val) {
        state.showMe = false;
        router.push({
            path: '/user',
            query: {
            type: val
            }
        })
    }
    const goW = ()=> {
    if (locale == 'zh') {
        window.open('https://republicofvertu.com/vpass/whitePaper/zh/index.html')
    } else if (locale == 'en') {
        window.open('https://republicofvertu.com/vpass/whitePaper/en/index.html')
    }
    }
    function logout(){
        router.push('/').then(()=>{
            location.reload()
        })
        main.clearCurrentUser()
        state.showMe = false;
    }
</script>
<style lang="less" scoped>
    .Mheader{
        position: fixed;
        top:0;
        width:100%;
        background: black;
        z-index: 1;
        .head{
            padding:0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            z-index: 1;
            background: black;
            height: 60px;
            img{
                height: 28px;
            }
        }
        .jLAyPo{
            position: absolute;
            height: 100vh;
            top:60px;
            left: 0px;
            transition: transform 1s ease 0s;
            transform: translateX(0);
            background: rgba(18, 18, 20, 0.9);
            backdrop-filter: blur(20px);
            padding-left: 20px;
            z-index: 3;
        }
        .kvNLLB{
            position: absolute;
            height: 100vh;
            top:60px;
            left: 0px;
            transition: transform 1s ease 0s;
            transform: translateX(-100%);
            background: rgba(18, 18, 20, 0.9);
            backdrop-filter: blur(20px);
            padding-left: 20px;
        }
        .mask{
            position: fixed;
            width: 100vw;
            height: 100vh;
            left: 0;
            top:60px;
            background-color: #34323280;
            z-index: 1; 
        }
        .hide{
            visibility: hidden;
        }
        .menu{
          font-family: 'Qurany-Bold';
          width: 70vw;
            margin:0 auto;
            height: 60px;
            line-height: 60px;
            text-align: center;
            color: #8C8C8C;
            border-bottom: 1px solid #343232;
            z-index: 3;
            font-size: 16px;
        }
        .active {
            color: #fff;
        }

        .user-nav {
            position: absolute;
            right: 5px;
            top: 50px;
            background: url('@/assets/img/bg-07.png') no-repeat;
            background-size: cover;
            padding-top: 5px;
            border-radius: 4px;
            min-width: 135px;
            height: 160px;
            

            &-list {
            color: #000;
            text-align: center;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 12px;
            cursor: pointer;
            height: 40px;
            line-height: 40px;
            color: #2c3e50;
            }
        }
        .avatar{
            border-radius: 50%;
        }
    }
</style>

import { createI18n } from "vue-i18n";
import { locale } from "../services";
import zh from "./zh.json";
import en from "./en.json";

// if (!localStorage.getItem("lang")) {
    if(navigator.language == 'zh-CN'||navigator.language.includes('zh')){
        localStorage.setItem("lang", 'zh')
    }else{
        localStorage.setItem("lang", 'en')
    }
// }
export const f = localStorage.getItem("lang")
const i18n = createI18n({
    locale,
    messages: {
        zh,
        en,
    },
});

export default i18n;

<template>
  <div class="manifesto">
    <div class="manifesto-container">
      <div class="manifesto-title" v-animate>
        {{ $t('meta.manifesto.title') }}
      </div>
      <div class="manifesto-text">
        <div v-animate>{{ $t('meta.manifesto.text.line1') }}</div>
        <div v-animate>{{ $t('meta.manifesto.text.line2') }}</div>
      </div>
      <div class="btns">
        <div @click="go" class="manifesto-btn" v-animate> {{ $t('meta.manifesto.buy') }}</div>
        <!-- <div @click="goW" class="manifesto-btn w" v-animate> {{ $t('meta.manifesto.whitepaper') }}</div> -->
      </div>
    </div>
    <!-- <img src="@images/meta/manifesto.png" alt=""> -->
  </div>
</template>

<script lang='ts'>
import {animateloop2} from "@/utils/animateloop.js"
import {buyMetavertu} from '@/utils/index.js'
import {locale} from "@/services"

export default {
  data() {
    return {
      showTop: false,
      showBottom: false,
      dom: '',
      shoW: false,
      t: 1,
      locale
    }
  },
  mounted() {
  },
  methods: {
    go() {
      // 买手机
      buyMetavertu()
    },
    goW() {
      if (locale == 'zh') {
        window.open('https://republicofvertu.com/vpass/whitePaper/zh/index.html')
      } else if (locale == 'en') {
        window.open('https://republicofvertu.com/vpass/whitePaper/en/index.html')
      }
    }
  }
}
</script>

<style lang='less' scoped>
.manifesto {
  @media screen and (min-width: 768px) {
    height: 360px;
  }
  .manifesto-container{
    height: 100%;
  }
  //height: 1275px;
  //background: url('@images/meta/manifesto.png') no-repeat bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &-container {
    color: #fff;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .manifesto-title {
      font-size: 100px;
      font-weight: 600;
      margin-top: 135px;
      margin-bottom: 20px;
      font-family: 'Qurany-Bold';
      //&.display{
      //  display: none;
      //  -webkit-transition: all 1s ease-in;
      //  -moz-transition: all 1s ease-in;
      //  transition: all 1s ease-in;
      //}
      //&.visible{
      //  display: inline-block;
      //  -webkit-transition: all 1s ease-in;
      //  -moz-transition: all 1s ease-in;
      //  transition: all 1s ease-in;
      //}
    }
    .manifesto-text {
      font-size: 34px;
      font-weight: 600;
      margin-bottom: 80px;
      text-align: center;
      font-family: 'Montserrat-Light-6';
      //&.display{
      //  display: none;
      //  -webkit-transition: all 1s ease-in;
      //  -moz-transition: all 1s ease-in;
      //  transition: all 1s ease-in;
      //}
      //&.visible{
      //  display: inline-block;
      //  -webkit-transition: all 1s ease-in;
      //  -moz-transition: all 1s ease-in;
      //  transition: all 1s ease-in;
      //}
    }
    .btns {
      display: flex;
      gap: 40px;
    }
    .manifesto-btn {
      &.w {
        background: #fff;
        color: #000;
      }
      font-family: 'Montserrat-Light-6';
      width: 200px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 24px;
      border: 1px solid #fff;
      cursor: pointer;
      //&.display{
      //  display: none;
      //  -webkit-transition: all 1s ease-in;
      //  -moz-transition: all 1s ease-in;
      //  transition: all 1s ease-in;
      //}
      //&.visible{
      //  display: inline-block;
      //  -webkit-transition: all 1s ease-in;
      //  -moz-transition: all 1s ease-in;
      //  transition: all 1s ease-in;
      //}
    }
  }
}
@media screen and (max-width: 768px) {
  .manifesto {
    height: 290px;
    .manifesto-container{
      height: 100%;
    }
    //background-image: url('@images/meta/manifesto-m.png');
    background-size: cover;
    .manifesto-title {
      font-size: 32px;
      margin-top: 0px;
      margin-bottom: 12.5px;
    }
    .manifesto-text {
      font-size: 12px;
      line-height: 22px;
      margin-bottom: 32px;
    }
    .manifesto-btn {
      width: 100px;
      height: 40px;
      line-height: 40px;
      font-size: 12px;
      border: 1px solid #fff;
      text-align: center;
    }
  }
}
</style>
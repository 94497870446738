<template>
    <div class="rephone">
        <Head/>
        <div class="banner">
            <video class="index-video" poster="" muted autoplay="autoplay" loop="loop" webkit-playsinline='true'
                   playsinline='true'>
                <source src="@/assets/gif/background.mp4"/>
            </video>
            <div class="text animate__animated animate__zoomIn  animate1">
                <p >{{$t('republicH5.technology')}}</p>
<!--                <div >DIGITAL of THE PEOpLE, BY THE PEOPLE, OF THE<br>-->
                <div >DATA GOVERNANCE OF THE PEOPLE, BY THE PEOPLE,  <br>
                  FOR THE PEOPLE</div>
            </div>
        </div>
        <div class="main">
            <div class="box pad animate__animated  animate__backInLeft animate2">
                <div class="title ">METAVERTU</div>
                <div class="subtitle">{{$t('republicH5.web3phone')}}</div>
                <div class="line "></div>
                <div class="flex" @click="buyMetavertu">
                  {{$t('republicH5.buy')}}
                    <img  src="@/assets/h5/right.png" alt="" style='width:16px'/>
                </div>
                <div class="flex" @click="toHome">{{$t('republicH5.detail')}}
                    <img  src="@/assets/h5/right.png" alt="" style='width: 16px'/>
                </div>
            </div>
            <video class="index-video" poster="" muted autoplay="autoplay" loop="loop" webkit-playsinline='true'
                   playsinline='true'>
                <source src="@/assets/gif/page1.mp4"/>
            </video>
            <div class="box-father  animate__animated animate__backInRight animate3">
                <div class="box pad1">
                    <div class="title ">{{$t('republicH5.vapass')}}</div>
                    <div class="subtitle " style="max-width: 250px;">{{$t('republicH5.find')}}</div>
                    <div class="line "></div>
                    <!-- <div class="flex ">{{$t('republicH5.buy')}}
                        <img  src="@/assets/h5/right.png" alt="" style='width: 16px'/>
                    </div> -->
                    <div class="flex " @click="openToVpass">{{$t('republicH5.detail')}}
                        <img  src="@/assets/h5/right.png" alt="" style="width: 16px"/>
                    </div>
                </div>
            </div>
            <video class="index-video" poster="" muted autoplay="autoplay" loop="loop" webkit-playsinline='true'
                   playsinline='true'>
                <source src="@/assets/gif/page2.mp4"/>
            </video>
            <div class="box pad animate__animated animate__backInLeft animate4">
                <div class="title ">{{$t('republicH5.ecology')}}</div>
                <div class="subtitle ">{{$t('republicH5.start')}}</div>
                <div class="line "></div>
                <div class="flex ">
                    <p class="gary">Dappstore</p>
                    <div>{{$t('republicH5.store')}}</div>
                </div>
                <div class="flex margin ">
                    <p class="gary">VBox</p>
                    <div>{{$t('republicH5.save')}}</div>
                </div>
                <div class="flex margin ">
                    <p class="gary">Vtalk</p>
                    <div>{{$t('republicH5.encryption')}}</div>
                </div>
                <div class="flex margin ">
                    <p class="gary">Vshot</p>
                    <div>{{$t('republicH5.camera')}}</div>
                </div>
                <div class="flex margin ">
                    <p class="gary">Value</p>
                    <div>{{$t('republicH5.wallet')}}</div>
                </div>
            </div>
            <video class="index-video" poster="" muted autoplay="autoplay" loop="loop" webkit-playsinline='true'
                   playsinline='true'>
                <source src="@/assets/gif/page3.mp4"/>
            </video>
            <div class="box text-center margin animate__animated animate__bounceIn animate5">
                <div class="title ">{{$t('republicH5.plan')}}</div>
                <div class="subtitle" style="padding: 0 20px" v-if="locale == 'zh'">{{$t('republicH5.desc')}}</div>
              <div class="subtitle" style="padding: 0 20px" v-else>{{$t('republicH5.desc')}}
                {{$t('republicH5.desc1')}}</div>
                <div class="come ">COMING SOON</div>
                <div class="foot">
                    <div class="flex-center">
                        <div class="column">
                            <img src='@/assets/code.png' alt='' class="code"/>
                            <span>{{$t('republicH5.coder')}}</span>
                        </div>
                        <div class="column">
                            <img src='@/assets/tag.png' alt='' class="tag"/>
                            <span>{{$t('republicH5.reward')}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="logo">
                <img src='@/assets/logo.png' alt='' class="logo"/>
            </div>
        </div>
        
        
    </div>
</template>
<script setup lang="ts">
    import Head from './components/Head.vue'
    import './index.less'
    import {onMounted,ref} from 'vue'
    import {locale} from "@/services/index";

    import router from '@/router';
    import {buyMetavertu,openToVpass} from '@/utils/index'

    const toHome =()=>{
        router.push('/metavertu')
    }
    
    onMounted(()=>{
        let animate1 = document.getElementsByClassName('animate1')
        let animate2 = document.getElementsByClassName('animate2')
        let animate3 = document.getElementsByClassName('animate3')
        let animate4 = document.getElementsByClassName('animate4')
        let animate5 = document.getElementsByClassName('animate5')

        window.onscroll = function() {
            scroll(animate1[0],'animate__zoomIn','animate__zoomOut')
            scroll(animate2[0],'animate__backInLeft','animate__backOutLeft')
            scroll(animate3[0],'animate__backInRight','animate__backOutRight')
            scroll(animate4[0],'animate__backInLeft','animate__backOutLeft')
            scroll(animate5[0],'animate__bounceIn','animate__bounceOut')
        }     
    })
    function GetRect (element:any) {
      var rect = element?.getBoundingClientRect() // 距离视窗的距离
      var top = document.documentElement.clientTop ? document.documentElement.clientTop : 0 // html元素对象的上边框的宽度
      var left = document.documentElement.clientLeft ? document.documentElement.clientLeft : 0
      return {
        top: rect?.top - top,
        bottom: rect?.bottom - top,
        left: rect?.left - left,
        right: rect?.right - left
      }
    }
    const scroll=(el:any,className:string,className1:string)=>{
        var obj = GetRect(el)
        var divH = el?.offsetHeight // div高度
        var windowH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight // 浏览器高度兼容写法
      if (obj.top >= windowH) { // 在视口之下
        // console.log('84在视口之下')
        el.classList.remove(className)
        el.classList.add(className1)
      }
      if (obj.top < windowH && obj.bottom >= windowH) { // 正在出现
        // console.log('84正在出现')
        el.classList.add(className)
        el.classList.remove(className1)
      }
      if (obj.top > 0 && obj.top < windowH && obj.bottom > 0 && obj.bottom < windowH) { // 正在视口中
        // console.log('84正在视口中')
        el.classList.add(className)
        el.classList.remove(className1)
      }
      if (obj.top <= 0 && obj.bottom <= divH && obj.bottom > 0) { // 正在离开视口
        // console.log('84正在离开视口')
        el.classList.remove(className)
        el.classList.add(className1)
      }
      if (obj.bottom <= 0) { // 已经离开视口
        // console.log('84在视口之上')
        el.classList.remove(className)
        el.classList.add(className1)
      }
    }
</script>
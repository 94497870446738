<template>
  <div class="header">
    <img src="@images/logo.png" class="logo" alt="logo">
    <div class="tab">
      <div class="item" @click="toHome">
        HOME
      </div>
      <div class="item"  @click="toMeta">
        METAVERTU
      </div>
      <div class="item" @click="toVpass">
        VPASS
      </div>
    </div>
    <!--    <Login>-->
    <!--      <div class="user">-->
    <!--        <div class="login">{{ $t('login.register') }}</div>-->
    <!--      </div>-->
    <!--    </Login>-->
    <div class="avatar-box" v-if="main.currentUser.id" @click="showMenu">
      <img :src="main.currentUser.avatar1" alt="avatar" class="avatar">
      <div class="user-nav" v-if="state.showMe">
        <div class="user-nav-list" @click="goTo(0)">{{ $t('Head.nx8u7x') }}</div>
        <div class="user-nav-list" @click="goTo(1)">{{ $t('Head.rzn6l7') }}</div>
        <div class="user-nav-list" @click="goTo(2)">{{ $t('Head.jmizgm') }}</div>
        <div class="user-nav-list" @click="logout">{{ $t('Head.w69zi8') }}</div>
      </div>
    </div>
    <div class="login" @click="loginDialog" v-else>{{ $t('login.gzx7qc') }}</div>
    <login/>
  </div>
</template>

<script lang='ts' setup>
// import Login from '@/components/login.vue'
import login from '@/components/login2.vue'
import router from '@/router';
import {userStore} from '@/store/user'
import {reactive} from "vue";

const state = reactive({
  showMe: false
})
const main = userStore()
const loginDialog = () => {
  main.loginDialog = true;
}

function toHome() {
  router.push('/republic')
}
function toMeta() {
  router.push('/metavertu')
}

const showMenu = () => {
  state.showMe = !state.showMe;
}

function toVpass() {
  window.open('http://vpass.republicofvertu.com/#/')
}

function goTo(val: string) {
  state.showMe = false;
  router.push({
    path: '/user',
    query: {
      type: val
    }
  })
}

function logout() {
  router.push('/metavertu').then(() => {
    location.reload()
  })
  main.clearCurrentUser()
  state.showMe = false;
}
</script>

<style lang="less" scoped>
.header {
  background-color: rgb(9, 9, 9);
  padding: 0 60px;
  height: 105px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 21;

  .logo {
    cursor: pointer;
  }

  .user-nav {
    position: absolute;
    left: -40px;
    top: 54px;
    height: 190px;
    background: url('@/assets/img/bg-07.png') no-repeat;
    background-size: contain;
    padding-top: 5px;
    width: 140px;
    border-radius: 4px;

    &-list {
      color: #000;
      line-height: 38px;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .tab {
    font-family: 'Qurany-Bold';
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    gap: 115px;
    color: #8C8C8C;
    cursor: pointer;

    .item.active {
      color: #fff;
    }
  }

  .user {
    display: flex;
    align-items: center;
    gap: 40px;
    font-size: 20px;
    color: #8A8A8A;

  }

  .login {
    font-size: 20px;
    color: #8A8A8A;
    cursor: pointer;
  }

  .avatar {
    cursor: pointer;
    width: 50px;
    border-radius: 50%;

    &-box {
      position: relative;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background: #fff;
    }
  }
}
</style>
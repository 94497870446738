<template>

  <div class="pc">
    <Head1 v-if="deviceName=='phone'"/>
    <Head v-else/>
    <div class="user" :class="deviceName=='phone'?'phone':''">
      <div class="user-bg"></div>
      <div class="user-c">
        <div class="user-head">
          <img class="avatar" :src="userData.avatar1" alt="">
          <div class="name">{{ userData.name }}</div>
          <div class="mobile">
            <img src="@/assets/user/mobile.png"/>
            {{ fnPhone }}
          </div>
        </div>

        <div class="tabs">
          <div class="item" v-for="(item) in tab_list" :key="item.id"
               :class="{'active':item.id == tab_active}" @click="tab_active=item.id">
            {{ item.name }}
          </div>
        </div>

        <KeepAlive>
          <component :is="tab_list[tab_active].comp"/>
        </KeepAlive>
      </div>
    </div>
    <Foot/>
  </div>
</template>

<script>
import {getUser, userList} from '@/services/person'
import {deviceName} from '@/utils'
import {userStore} from "@/store";
import Home from './home.vue'
import Record from './record.vue'
import Collect from './collect.vue'
import Head from '@/pages/metaphone/components/header'
import Head1 from '@/pages/republic/phone/components/Head'
import Foot from '@/pages/metaphone/components/footer'

export default {
  setup() {
    const store = userStore()
    return {
      store,
    }
  },
  data() {
    return {
      deviceName,
      tab_list: [
        {id: 0, name: this.$t('user.home.name'), comp: Home},
        {id: 1, name: this.$t('user.record.name'), comp: Record},
        {id: 2, name: this.$t('user.collect.name'), comp: Collect}
      ],
      tab_active: 0,
      appointment_data: [], // 预约2
      invitation_data: [], // 邀请1
      active: 0,
    }
  },
  components: {
    Home,
    Record,
    Head,Head1,
    Foot
  },
  computed: {
    userData() {
      return this.store?.currentUser;
    },
    fnName: function (value) {
      return value;
    },
    fnPhone: function () {
      if (this.userData && this.userData.phone) {
        let value = this.userData.phone;
        return value.substring(0, 3) + '****' + value.substring(value.length - 4);
      }else if(this.userData && this.userData.email){
        return  this.userData.email
      }
      else {
        return ''
      }
    }
  },
  watch: {
    '$route.query.type'(newValue, oldValue) {
      this.tab_active = newValue
    }
  },
  mounted() {
    this.store.fetchCurrentUser()
    // console.log(this.store.$state.currentUser,94,this.store.$state)
    // console.log(92, this.$route)
    if (this.$route.query && this.$route.query.type) {
      this.tab_active = this.$route.query.type
    }
  },
}
</script>

<style lang="less" scoped>

.pc {
  .user {
    min-height: 100vh;
    background-color: black;
    // background: url('../../assets/bg-01.png') no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding: 30px 100px;
    color: #fff;
    position: relative;
    .user-bg {
      position: absolute;
      top: 105px;
      left: 0;
      background: url('../../assets/user/bg.png') no-repeat;
      // background-size: 1529px 750px;
      background-position: -30px 0;
      background-size: 110% 100%;
      width: 100%;
      height: 600px;
    }
    .user-head {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      .avatar {
        margin-top: 80px;
        border: 3px solid #fff;
        width: 90px;
        height: 90px;
        border-radius: 100%;
        margin-bottom: 10px;
      }
      .name {
        font-size: 12px;
      }
      .mobile {
        display: flex;
        align-items: center;
        font-size: 9px;
        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }
    .tabs {
      font-family: 'Montserrat-Light-6';
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin: 50px 0;
      .item {
        width: 100px;
        padding-bottom: 20px;
        color: #999999;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        margin: 20px;
        &.active {
          color: #fff;
          background: url(../../assets/user/active.png) no-repeat bottom;
          background-size: 35px 12.5px;
        }
      }
    }
    .user-c {
      top: 105px;
      max-width: 1240px;
      margin: 0 auto;
      position: relative;
    }
    &-icon {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      padding-top: 40px;
      img {
        width: 17px;
        margin-right: 4px;
        &.on {
          overflow: hidden;
          border-radius: 30px;
        }
      }
      span {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .info {
      display: flex;
      // padding-bottom: 30px;
      &-list {
        line-height: 17px;
        label {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #888888;
          letter-spacing: 0;
          text-align: center;
          line-height: 17px;
          font-weight: 400;
        }
        span {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #CCCCCC;
          letter-spacing: 0;
          text-align: center;
          line-height: 17px;
          font-weight: 400;
        }
        margin-right: 20px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    ul {
      display: block;
      &.ul-info {
        li {
          box-sizing: border-box;
          height: 50px;
          background: #000000;
          border: 0.5px solid #3B3B3B;
          border-radius: 1px;
          margin-bottom: 10px;
          .li-1 {
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 50px;
            display: flex;
            justify-content: space-between;
          }
          &.on {
            height: 167px;
            border: 0.5px solid #FFFFFF;
            border-radius: 1px;
            background: none;
          }
          .li-2 {
            padding-top: 10px;
          }
          .li-list {
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 30px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .img {
      &-con {
        line-height: 1.5;
        text-align: center;
        .p1 {
          font-family: PingFangSC-Medium;
          font-weight: 500;
          font-size: 12px;
          color: #FFFFFF;
          padding-bottom: 12px;
          padding-top: 10px;
        }
        .p2 {
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 9px;
          color: #FFFFFF;
        }
      }
      &-list {
        img {
          width: 176px;
          height: 112px;
        }
      }
    }
    .box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &-list {
        background: url('./../../assets/bg-02.png') no-repeat;
        background-size: cover;
        width: 99px;
        height: 90px;
        box-sizing: border-box;
        position: relative;
        padding: 6px;
        margin-bottom: 20px;
        margin-right: 8px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &-num {
          font-family: DINAlternate-Bold;
          font-weight: Bold;
          font-size: 12px;
          color: #FFFFFF;
          .cc {
            width: 3.71px;
            margin-left: 3px;
          }
        }
        &-info {
          position: absolute;
          left: 32px;
          top: 18px;
          &-icon {
            margin-left: 6px;
            margin-bottom: 10px;
            border: 2px solid rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 6px 4px rgba(255, 255, 255, 0.5);
            border-radius: 30px;
            line-height: 0;
            img {
              width: 30px;
              height: 30px;
              border-radius: 20px;
            }
          }
          &-txt {
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 11px;
            color: #FFFFFF;
            width: 38px;
            height: 16px;
            overflow: hidden;
          }
        }
      }
    }
    &.phone {
      padding: 0;
      box-sizing: border-box;
      .user-bg {
        min-height: 500px !important;
        max-height: 500px !important;
        background: url('../../assets/user/banner.png') no-repeat;
        background-size: cover;
      }
      .tabs {
        margin: 0px;
        padding: 35px 25px;
        //background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 100%);
      }
      .phone {
        padding: 30px;
        box-sizing: border-box;
        margin-bottom: 120px;
        .list {
          .item {
            font-size: 14px;
            color: #FFFFFF;
          }
        }
      }
    }


  }
}


</style>
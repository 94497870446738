<template>
  <el-row class="container animate-custom " v-if="0">
    <el-col :xs="24" :md="12" class="text">
      <h1 v-animate>
        {{ $t('meta.design.item1.title') }}
      </h1>
      <h2 v-animate>{{ $t('meta.design.item1.content1.title') }}</h2>
      <h3 v-animate>
        <div>{{ $t('meta.design.item1.content1.text.line1') }}</div>
      </h3>

      <h2 v-animate>{{ $t('meta.design.item1.content2.title') }}</h2>
      <h3 v-animate>
        <div>{{ $t('meta.design.item1.content2.text.line1') }}</div>
        <div>{{ $t('meta.design.item1.content2.text.line2') }}</div>
      </h3>
    </el-col>
    <el-col :xs="24" :md="12" class="image">
      <img src="@images/meta/space.png" alt="">
    </el-col>
  </el-row>
  <el-row class="container animate-custom1" v-if="0">
    <el-col :xs="24" :md="12" class="image hidden-sm-and-down">
      <img src="@images/meta/teese.png" alt="">
    </el-col>
    <el-col :xs="24" :md="12" class="text">
      <h1 v-animate>{{ $t('meta.design.item2.title') }}</h1>

      <h2 v-animate>{{ $t('meta.design.item2.content1.title') }}</h2>
      <h3 v-animate>{{ $t('meta.design.item2.content1.text.line1') }} </h3>

      <h2 v-animate>{{ $t('meta.design.item2.content2.title') }}</h2>
      <h3 v-animate>{{ $t('meta.design.item2.content2.text.line1') }} </h3>

      <h2 v-animate>{{ $t('meta.design.item2.content3.title') }}</h2>
      <h3 v-animate>{{ $t('meta.design.item2.content3.text.line1') }} </h3>
    </el-col>
    <el-col :xs="24" :md="12" class="image hidden-sm-and-up">
      <img src="@images/meta/teese.png" alt="">
    </el-col>
  </el-row>

  <!--  <el-row class="container nop animate-custom2 ">-->
  <!--    <el-col :xs="24" :md="12" class="text display"-->
  <!--            :class="showTop2?'animate__animated animate__slideInUp visible':showBottom?'animate__animated animate__slideInDown display':''"-->
  <!--    >-->
  <!--      <h1>-->
  <!--        {{ $t('meta.design.item3.title.t') }}-->
  <!--        <div class="tips">{{ $t('meta.design.item3.title.tips') }}</div>-->
  <!--      </h1>-->
  <!--      <h2>{{ $t('meta.design.item3.content1.title') }}</h2>-->
  <!--      <h3>-->
  <!--        <div>{{ $t('meta.design.item3.content1.text.line1') }}</div>-->
  <!--        <div>{{ $t('meta.design.item3.content1.text.line2') }}</div>-->
  <!--      </h3>-->

  <!--      <h2>{{ $t('meta.design.item3.content2.title') }}</h2>-->
  <!--      <h3>-->
  <!--        <div>{{ $t('meta.design.item3.content2.text.line1') }}</div>-->
  <!--        <div>{{ $t('meta.design.item3.content2.text.line2') }}</div>-->
  <!--        <div>{{ $t('meta.design.item3.content2.text.line3') }}</div>-->
  <!--        <div>{{ $t('meta.design.item3.content2.text.line4') }}</div>-->
  <!--      </h3>-->
  <!--    </el-col>-->
  <!--    <el-col :xs="24" :md="12" class="image">-->
  <!--      <img src="@images/meta/eth.png" alt="">-->
  <!--    </el-col>-->
  <!--  </el-row>-->

  <div class="container2">
    <div class="title">
      {{ $t('meta.design.more.title') }}
    </div>
    <el-row class="">
      <el-col :xs="24" :md="8" class="extend">
        <h1 v-animate="{isToggle:false}">{{ $t('meta.design.more.left.h1') }}</h1>
        <h2 v-animate="{isToggle:false}">{{ $t('meta.design.more.left.h2') }}</h2>

        <div class="config">
          <div v-animate="{isToggle:false}">{{ $t('meta.design.more.left.config1.line1') }}</div>
          <div v-animate="{isToggle:false}">{{ $t('meta.design.more.left.config1.line2') }}</div>
        </div>
        <div class="config">
          <div v-animate="{isToggle:false}">{{ $t('meta.design.more.left.config2.line1') }}</div>
        </div>
        <div class="config">
          <div v-animate="{isToggle:false}">{{ $t('meta.design.more.left.config3.line1') }}</div>
        </div>
      </el-col>
      <el-col :xs="24" :md="8" class="phone">
        <img src="@images/meta/vphone.png" alt="" v-if="locale =='zh'">
        <img src="@images/meta/vphone-en.png" alt="" v-else>
        <h3 v-animate="{isToggle:false}">{{ $t('meta.design.more.middle.h1') }}</h3>
        <h1 v-animate="{isToggle:false}">{{ $t('meta.design.more.middle.h2') }}</h1>
      </el-col>
      <el-col :xs="24" :md="8" class="extend">
        <h1 v-animate="{isToggle:false}">{{ $t('meta.design.more.right.h1') }}</h1>
        <h2 v-animate="{isToggle:false}">{{ $t('meta.design.more.right.h2') }}</h2>

        <img src="@images/meta/universe.png" alt="">
      </el-col>
    </el-row>
  </div>
</template>

<script lang='ts'>
import { locale } from "@/services/index";

export default {
  data() {
    return {
      locale,
    }
  },
  mounted() {
  }
}
</script>

<style lang='less' scoped>
img {
  width: 100%;
}

.animate-custom,
.animate-custom1,
.animate-custom2 {
  .text {
    &.display {
      display: none;
      -webkit-transition: all 1s ease-in;
      -moz-transition: all 1s ease-in;
      transition: all 1s ease-in;
    }

    &.visible {
      display: block;
      -webkit-transition: all 1s ease-in;
      -moz-transition: all 1s ease-in;
      transition: all 1s ease-in;
    }
  }
}

@media screen and (min-width: 768px) {
  .animate-custom1 {
    .text {
      padding: 0  50px 0 0;
    }
  }
  .animate-custom2 {
    .text {
      padding: 0  0 0 50px;
    }
  }
}
.container {
  color: #fff;
  padding: 150px 100px;
  align-items: center;
  .text {
    //padding: 0 50px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Montserrat-Light-6';
    h1 {
      font-family: 'Qurany-Bold';
      font-size: 60px;
      font-weight: 900;
      margin-bottom: 68px;
      .tips {
        font-size: 18px;
        font-weight: normal;
        color: #818181;
        font-family: 'Montserrat-Light-6';
      }
    }
    h2 {
      font-size: 26px;
      margin-bottom: 25px;
      font-family: 'Montserrat-Light-6';
    }
    h3 {
      font-family: 'Montserrat-Light-6';
      font-size: 20px;
      font-weight: normal;
      color: #9F9F9F;
      margin-bottom: 35px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .image {
    padding: 30px;
  }
}
.container2 {
  padding: 150px 30px;
  font-family: 'Montserrat-Light-6';
  color: #fff;
  .title {
    font-size: 60px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 150px;
    font-family: 'Qurany-Bold';
    width: 100%;

    &.display {
      display: none;
      -webkit-transition: all 1s ease-in;
      -moz-transition: all 1s ease-in;
      transition: all 1s ease-in;
    }
    &.visible {
      display: inline-block;
      -webkit-transition: all 1s ease-in;
      -moz-transition: all 1s ease-in;
      transition: all 1s ease-in;
    }
  }
  .extend {
    font-family: 'Montserrat-Light-6';
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #272727;
    &:last-of-type {
      border: none;
    }
    h1 {
      font-size: 26px;
      font-weight: normal;
    }
    h2 {
      font-size: 36px;
      font-weight: 900;
      margin-bottom: 10px;
      text-align: center;
    }
    .config {
      margin-top: 30px;
      border-radius: 20px;
      border: 6px solid #fff;
      box-shadow: 0 0 31px 7px #04CEFF inset, 0 0 31px 7px #04CEFF;
      width: 60%;
      height: 161px;
      font-size: 37px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-family: 'Montserrat-Light-6';
    }
    img {
      width: auto;
      max-width: 100%;
      // padding: 50px;
    }
  }
  .phone {
    gap: 10px;
  }
  .extend,
  .phone {
    font-family: 'Montserrat-Light-6';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #272727;
    color: #fff;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 40px 0;
    &.nop {
      padding-bottom: 0;
    }

    .text {
      padding: 0 25px;
      h1 {
        text-align: center;
        font-size: 26px;
        margin-bottom: 32px;
        .tips {
          font-size: 11px;
          margin-top: 6px;
        }
      }
      h2 {
        font-size: 14px;
        margin-bottom: 9px;
      }
      h3 {
        font-size: 11px;
      }
    }
    .image {
      padding: 10px 8px;
    }
  }
  .container2 {
    padding: 60px 0;
    color: #fff;
    .title {
      font-size: 26px;
      margin-bottom: 0;
        //padding: 0 25px;
      padding: 25px 25px 50px;
      box-sizing: border-box;
    }
    .extend {
       padding: 32px 0;
      h1 {
        font-size: 13px;
        margin-bottom: 2px;
      }
      h2 {
        font-size: 18px;
      }
      .config {
        margin-top: 15px;
        height: 80px;
        font-size: 19px;
        font-weight: 400;
        border: 3px solid #fff;
        border-radius: 10px;
        box-shadow: 0 0 15px 3px #04CEFF inset, 0 0 15px 3px #04CEFF;
      }
      img {
        width: 75%;
      }
    }
    .phone {
      padding:0 10px 50px;
      h3 {
        font-size: 13px;
      }
      h1 {
        font-size: 22px;
      }
    }
    .extend,
    .phone {
      border-bottom: 1px solid #272727;
    }
  }
}
</style>
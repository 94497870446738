<template>
  <div class="pc" :class="deviceName=='phone'?'phone':''">
    <!--    <Empty v-if="!userData.invitation_code" type='1' :text="$t('user.home.nodata1')" @click="toHome"/>-->
    <!--    <div v-else class="poster">-->
    <div class="poster user-poster">
      <!--      3=>未开奖-->
      <!--      4=>已中奖-->
      <!--      5=>未中奖-->
      <!--      6=>已兑换-->
      <h2>{{ $t('user.home.invite') }}</h2>
      <div v-if="userData?.gift_status===3">
        <div class="desc">
          <span>{{ $t('user.home.inviteDesc1') }}</span>
<!--          <p>{{ $t('user.home.inviteDesc2') }}</p>-->
          <p>{{ $t('user.home.inviteDesc3') }}</p>
        </div>
        <div class="icons">
          <div class="figure figure1" v-if="f == 'zh'">
            <div class="img">
              <img src="@/assets/user/arcode1.png" alt=""/>
            </div>
            <figcaption>{{ $t('user.home.qrcode') }}</figcaption>
          </div>
          <div class="figure" v-else @click="toDiscode">
            <div class="img">
              <img src="@/assets/user/icon1.png" alt=""/>
            </div>
            <figcaption>{{ $t('user.home.clickcode') }}</figcaption>
          </div>
        </div>
      </div>
      <div v-else-if="userData?.gift_status===5">
        <div class="desc no-prize">
          <img src="@/assets/user/no-prize.png" alt=""/>
          <span>{{ $t('user.home.inviteDesc1') }}</span>
          <p>{{ $t('user.home.noprize') }}</p>
        </div>
      </div>
      <div v-else-if="userData?.gift_status===4||userData?.gift_status===6">
        <div class="desc prized">
          <img src="@/assets/user/prized.png" alt=""/>
          <p>{{ $t('user.home.prized') }}</p>
          <p class="get">
            {{ $t('user.home.prized1') }}
            <img src="@/assets/user/prizedicon.png" alt=""/>
            {{ $t('user.home.prized2') }}
          </p>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {copyTxt} from '@/utils'
import {userList} from '@/services/person'
import {f} from '@/language'
import router from '@/router.ts';
import html2canvas from "html2canvas";
import copy from 'clipboard-copy'
import Line from './line.vue'
import Empty from './empty.vue'
import {userStore} from "@/store";
import {deviceName} from "@/utils/index.js";
import {locale} from "@/services";

export default {
  components: {
    Line,
    Empty
  },
  setup() {
    const store = userStore()
    return {
      store,
    }
  },
  data() {
    return {
      deviceName,
      f,
      isDev: location.pathname.substring(0, 5) === "/test" || location.hostname === "localhost" || location.hostname.startsWith('192.') || location.hostname.startsWith('127.'),
      list: []
    }
  },
  computed: {
    userData() {
      return this.store?.currentUser;
    },

  },
  async mounted() {
    // console.log(this.userData, 45)
  },
  methods: {
    toHome() {
      router.push('/')
    },
    toDiscode() {
      location.href = `https://discord.gg/republicofvertu`;
    }
  },

}
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .user-poster{
    margin-bottom: 140px;
  }
}
@media screen and (max-width: 768px) {
  .user-poster{
    margin-bottom: 30px;
  }
}
.pc {
  .title {
    font-size: 14px;
    margin: 20px 0;
    position: relative;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  @media screen {
    .poster {
      font-family: 'Montserrat-Light-6';
      //margin-bottom: 100px;
      color: #000000;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 1px 2px 0 #fff;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 90px 0;
      box-sizing: border-box;

      h2 {
        font-family: 'Qurany-Bold';
        font-size: 22px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        margin-bottom: 25px;
      }
      .desc {
        text-align: center;
        p {
          font-weight: 400;
          font-size: 12px;
          color: #1A1A1A;
          letter-spacing: 0;
          text-align: center;
          margin: 0 0 8px;
        }
        span {
          display: inline-block;
          min-width: 115px;
          width: auto;
          padding: 10px 15px;
          margin: 0px 0 25px;
          border: 1px solid #979797;
          border-radius: 37.5px;
          font-weight: Bold;
          font-size: 12px;
          color: #494949;
          letter-spacing: 0;
          box-sizing: border-box;
        }
        &.no-prize {
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            color: #C7C6C6 !important;
            letter-spacing: 0;
            font-size: 12px;
          }
          img {
            display: inline-block;
            width: 195px;
            height: 195px;
            margin: 0 auto;
          }
        }
        &.prized {
          p {
            font-weight: Bold;
            font-size: 16px;
            color: #232323;
            letter-spacing: 0;
          }
          img {
            display: inline-block;
            width: 195px;
            height: 195px;
          }
          .get {
            font-weight: 400;
            font-size: 16px;
            color: #232323;
            display: flex;
            align-items: center;
            gap: 5px;
            img {
              width: 22px;
              height: 22px;
              mix-blend-mode: multiply;
            }
          }
        }
      }
      .icons {
        display: flex;
        margin-top: 35px;
        gap: 25px;
        justify-content: center;
        .figure {
          text-align: center;
          .img {
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 8px;
            width: 90px;
            height: 90px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 23px;
            img {
              width: 35px;
              height: 25px;
            }
          }
          figcaption {
            font-weight: 600;
            font-size: 12px;
            color: #1A1A1A;
            letter-spacing: 0;
          }
        }
        .figure1 {
          .img {
            text-align: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  @media screen {
    &.phone {
      padding: 35px 30px;
      box-sizing: border-box;
      .poster {
        padding: 40px 0;
        .no-prize {
          span {
            padding: 10px 15px;
            box-sizing: border-box;
          }
        }
        .no-prize, .prized {
          padding: 35px 0 0;
        }
      }
    }
  }
}
</style>

<template>
    <Phone v-if="isPhone" />
    <Pc v-else/>
</template>

<script setup lang="ts">
    import {onMounted,ref} from 'vue'
    import Pc from './pc/index.vue'
    import Phone from './phone/index.vue'
    const isPhone = ref()
    isPhone.value = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)? true : false;
    onMounted(()=>{
        scrollTo(0, 0);
        // 监听窗口变化
      window.addEventListener('resize',function () {
         isPhone.value = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)? true : false;
      })       
    })

</script>

<style scoped>

</style>
<template>
  <div class="footer">
    <div class="footer-title">METAVERTU</div>
    <div class="footer-links">
      <a href="https://discord.gg/republicofvertu"><img src="@images/meta/icon/discord@3x.png" alt=""></a>
      <a href="https://www.instagram.com/republicofvertu/"><img src="@images/meta/icon/ins@3x.png" alt=""></a>
      <a href="https://republicofvertu.com/metavertu/#/"><img src="@images/meta/icon/ball@3x.png" alt=""></a>
      <!--            <a href="javascript:;"><img src="@images/meta/icon/dy.png" alt=""></a>-->
      <a href="https://twitter.com/republicofvertu"><img src="@images/meta/icon/tw.png" alt=""></a>
      <!--            <a href="javascript:;"><img src="@images/meta/icon/fs.png" alt=""></a>-->
      <a href="https://medium.com/@republicofvertu"><img src="@images/meta/icon/m.png" alt=""></a>
      <!--            <a href="javascript:;"><img src="@images/meta/icon/ytb.png" alt=""></a>-->
      <!--            <a href="javascript:;"><img src="@images/meta/icon/em.png" alt=""></a>-->
    </div>
    <div class="footer-subtitle">{{ $t('meta.footer') }}</div>
  </div>
</template>

<script lang='ts'>
export default {
  data() {
    return {}
  }
}
</script>

<style lang='less' scoped>
.footer {
  padding: 75px 0 100px;
  text-align: center;
  background-color: #090909;
  font-family: 'Montserrat-Light-6';
  &-title {
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 90px;
    font-family: 'Qurany-Bold';
    color: #fff
  }
  &-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    a {
      width: 48px;
      height: 48px;
      transition: all ease .2s;
      &:hover {
        transform: translateY(-5px);
      }
      img {
        width: 100%;
      }
    }
  }
  &-subtitle {
    margin-top: 38px;
    font-size: 20px;
    color: #3B3B3B;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 40px 33px;
    &-title {
      font-size: 26px;
      margin-bottom: 32px;
    }
    &-links {
      gap: 15px;
      a {
        width: 24px;
        height: 24px;
      }
    }
    &-subtitle {
      margin-top: 15px;
      font-size: 11px;
    }
  }
}
</style>
<template>
        <router-view />
</template>

<script lang="ts">
import { userStore } from '@/store'

export default {
    setup() {
        const store = userStore()
        return {
            store,
        }
    },
    data() {
        return {}
    },
    mounted() {
        // this.store.fetchCurrentUser()
    }
}
</script>
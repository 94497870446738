<template>
  <div class="recruit">
    <div class="recruit-title" v-animate>{{ $t('meta.design.recruit.title') }}
    </div>
    <div class="recruit-intro">
      <div v-animate>{{ $t('meta.design.recruit.line1') }}</div>
      <div v-animate>{{ $t('meta.design.recruit.line2') }}</div>
      <div v-animate>{{ $t('meta.design.recruit.line3') }}</div>
    </div>
    <div class="recruit-blocks">
      <div class="block">
        {{ $t('meta.design.recruit.block1.title1') }}
        <div class="button" @click="toBlock">{{ $t('meta.design.recruit.block1.title2') }} ></div>
      </div>
      <div class="block">
        {{ $t('meta.design.recruit.block2.title1') }}
        <div class="email">
          <img src="@images/meta/email.png" />
          {{ $t('meta.design.recruit.block2.title2') }}
        </div>
      </div>
    </div>
    <img class="recruit-map" src="@images/meta/map.png" />
  </div>
</template>

<script lang='ts'>
export default {
  data() {
    return {
      showTop: false,
      showBottom: false,
    }
  },
  methods: {
    toBlock() {
      window.open('https://airtable.com/shrVgQcuP4w2hNp03')
    }

  },
}
</script>

<style lang='less' scoped>
.recruit {
  padding: 200px 0;
  text-align: center;

  &-title {
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 50px;
    font-family: 'Qurany-Bold';
    color:#fff;
  }

  &-intro {
    font-size: 24px;
    line-height: 42px;
    margin-bottom: 110px;
    font-family: 'Montserrat-Light-6';
    color:#fff;
  }

  &-blocks {
    display: flex;
    align-items: center;
    gap: 100px;
    margin: 0 100px;
    justify-content: center;

    .block {
      font-family: 'Qurany-Bold';
      color: #fff;
      flex: 1;

      @media screen and (min-width: 768px) {
        width: 600px;
        max-width: 600px;
      }

      height: 265px;
      border: 4px solid #fff;
      //padding: 85px 130px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-weight: 900;
      gap: 20px;

      .button {
        background: url('@images/meta/button.png') no-repeat center;
        background-size: cover;
        width: 160px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 20px;
        color: #353738;
        cursor: pointer;
      }

      .email {
        font-size: 20px;
        color: #969696;
        display: flex;
        align-items: center;
        gap: 20px;
        word-break: break-word;
        font-family: 'Montserrat-Light-6';
      }
    }
  }

  &-map {
    margin-top: 85px;
  }
}

@media screen and (max-width: 768px) {
  .recruit {
    padding: 0 25px;

    &-title {
      font-size: 26px;
      margin-bottom: 14px;
    }

    &-intro {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 25px;
    }

    &-blocks {
      display: block;
      margin: 0 30px;

      .block {
        height: 132px;
        border: 2px solid #fff;
        font-size: 18px;
        gap: 13px;
        margin-bottom: 20px;

        .button {
          width: 80px;
          height: 24px;
          line-height: 24px;
          font-size: 10px;
        }

        .email {
          font-size: 11px;
          gap: 10px;

          img {
            width: 12px;
          }
        }
      }
    }

    &-map {
      margin: 0;
      width: 260px;
    }
  }
}
</style>
import axios from "axios";
import type { AxiosRequestConfig } from "axios";
import { ElMessage } from "element-plus";
import { locale } from "@/services";
import { getToken } from "@/utils/storge";
import {isDev} from "@/utils/index.js"
type CodeMessage = boolean | Record<number, string>;

interface RequestConfig extends AxiosRequestConfig {
    /**
     * http 状态码对应的提示信息
     */
    codeMessage?: CodeMessage;
    isJson?: boolean;
}

// create an axios instance
const service = axios.create({
    baseURL: `https://republicofvertu.com${isDev ? '/test' : ''}/v2appointment/api`, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 500000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        config.headers = {
            usertime: (Date.now() / 1000).toFixed(0),
            lang: locale,
            token: getToken(),
        };
        return config;
    },
    (error) => {
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log("error", error);
        if (error.response.status == "401") {
            ElMessage({
                message: "Login Failure",
                type: "error",
                duration: 3000,
            });
        }
        return error;
    }
);

export async function get<T = any>(url: string, config: RequestConfig = {}) {
    let resp = await service.get<T>(url, config);
    return resp.data || resp;
}

export async function post<T = any>(
    url: string,
    data: any = {},
    config: RequestConfig = {}
) {
    const { isJson = false } = config;
    const formData = isJson ? JSON.stringify(data) : data;
    let resp = await service.post<T>(url, formData, config);
    return resp.data || resp;
}

export async function put<T = any>(
    url: string,
    data: any = {},
    config: RequestConfig = {}
) {
    const { isJson = false } = config;
    const formData = isJson ? JSON.stringify(data) : data;
    let resp = await service.put<T>(url, formData, config);
    return resp.data || resp;
}

export async function del<T = any>(url: string, config: RequestConfig = {}) {
    let resp = await service.delete<T>(url, config);
    return resp.data || resp;
}

<template>
  <div :class="state.deviceName == 'pc'?'pc':'Mlogin'" >
    <div class="login " v-if="main.loginDialog">
      <div class="bg" @click="close"></div>
      <div class="login-box">
        <div class="title"> {{ $t('login.penzbe') }}</div>
        <img src="/src/assets/img/close.png" class="close" @click="close" alt="">

        <div class="tab-change" v-if="!state.IsChina">
          <ul>
            <li v-for="(item,n) in state.arr" :key="n" @click="tabClick(n)" :class="n == state.active?'on':''">
              {{ item }}
            </li>
          </ul>
        </div>

        <el-form
            ref="ruleFormRef"
            :model="state.ruleForm"
            :rules="state.rules"
        >
          <el-form-item label="" prop="email" v-if="state.active == 0 && state.IsChina == false">
            <el-input v-model="state.ruleForm.email" type="text" :placeholder="$t('login.bvss34')"/>
          </el-form-item>
          <el-form-item label="" prop="phone" v-else-if="state.IsChina" class="phone-form">
            <el-input v-model="state.ruleForm.phone" type="text" :placeholder="$t('login.bvss33')"/>
          </el-form-item>

          <el-form-item label="" prop="phone" v-else class="phone-form">
            <!-- <div class="dis_flex dis_sb"> -->
            <el-select v-model="state.cc" placeholder="Country"
                       style="dislpay: inline-block; width: 30%;margin-right: 10px;">
              <el-option
                  v-for="item in state.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-input style="dislpay: inline-block; width: 65%;" v-model="state.ruleForm.phone" type="text"
                      :placeholder="$t('login.bvss33')"/>
            <!-- </div> -->
          </el-form-item>
          <el-form-item label="" prop="imgCaptcha" v-if="state.IsChina || state.active != 0">
            <div class="img-captcha-box">
              <el-input v-model="state.ruleForm.imgCaptcha" :placeholder="$t('login.se5img')"/>
              <img v-if="state.iv" :src="state.captchaImgUrl" alt="img" @click="getImgCode">
            </div>
          </el-form-item>
          <el-form-item label="" prop="captcha">
            <div class="captcha-box">
              <el-input v-model="state.ruleForm.captcha" :placeholder="$t('login.se5ywe')"/>
              <span v-if="state.active==0 && locale === 'en'" class="yzm"
                    :class="state.code_txt != $t('login.gpyjzq') ? 'on' : ''"
                    @click="onSendEmail($t('login.gpyjzq'))">{{ state.code_txt }}</span>
              <span v-else class="yzm" :class="state.code_txt != $t('login.gpyjzq') ? 'on' : ''"
                    @click="onSendCode($t('login.gpyjzq'))">{{ state.code_txt }}</span>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="subLogin" @click="submitForm(ruleFormRef)">{{ $t('login.gzx7qc') }}</div>
          </el-form-item>
        </el-form>

      </div>
    </div>
  </div>
</template>

<script setup>
// const countryCode = require('@/utils/countryCode.json')
import countryCode from '@/utils/countryCode.json'
import {sendCode, login, sendEmail, getCaptcha, getUserByToken} from '@/services/user.ts'
import {getUser} from '@/services/person.ts'
import {locale} from "@/services/index.ts";
import {userStore} from '@/store/user.ts'
import {onMounted, reactive, ref} from "vue";
import {ElMessage} from 'element-plus';
import {setUser} from "@/utils/storge";

const main = userStore()
const ruleFormRef = ref()

const checkEmail = (rule, value, callback) => {
  if (value.trim() === '') {
    callback(new Error('Please input the email'))
  } else {
    callback()
  }
}
const checkCaptcha = (rule, value, callback) => {
  if (value.trim() === '') {
    callback(new Error('Please enter the 6-digit verification code'))
  } else {
    callback()
  }
}
const state = reactive({
  iv: '', // 图片验证码key
  captchaImgUrl: '', // 图片验证码图片
  cc: '',
  options: [],
  btnDisabled: false,
  active: 0,
  timeId: '',
  arr: ['Email', 'Mobile phone'],
  ruleForm: {
    phone: '',
    captcha: '',
    imgCaptcha: '',
    email: ""
  },
  IsChina: locale === 'zh',
  rules: {
    phone: [
      {required: true, message: locale === 'zh' ? "请输入手机号" : "Please enter your mobile phone number", trigger: 'blur'},
    ],
    captcha: [
      {
        required: true,
        message: locale === 'zh' ? "请输入6位验证码" : "Please enter the 6-digit verification code",
        trigger: 'blur'
      },
      {validator: checkCaptcha, trigger: 'blur'}
    ],
    email: [{validator: checkEmail, trigger: 'blur'}],
  },
  code_txt: locale === 'zh' ? "获取验证码" : "Get", //'获取验证码',
  deviceName: navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? 'phone' : 'pc'
})

main.$subscribe((mutation, p) => {
  if (p.loginDialog === true) {
    getImgCode()
  }
})

async function getImgCode() {
  await getCaptcha().then(res => {
    state.captchaImgUrl = res.data.url;
    state.iv = res.data.iv;
  })
}

function loginDialog() {
  return main.loginDialog
}

function tabClick(n) {
  state.active = n
}

function bodyScroll(event) {
  event.preventDefault();
}

function close() {
  main.loginDialog = false;
  document.body.addEventListener('touchmove', bodyScroll, false);
  document.body.style.overflow = '';
}

async function submitForm(el) {
  if (!el) return;
  await el.validate((valid) => {
    if (valid) {
      let data = state.ruleForm;
      data.captcha = data.captcha.toString();
      data.type = state.IsChina ? 2 : 3; // 2国内，3国外
      if (state.IsChina) {
        delete data.email
      } else {
        if (state.active == 0) {
          delete data.phone
        } else if (state.active == 1) {
          delete data.email
        }
      }
      login(data).then(async res => {
        if (res.code == 0) {
          localStorage.token = res.data;
          main.loginDialog = false;
          document.body.addEventListener('touchmove', bodyScroll, false);
          document.body.style.overflow = '';
          main?.fetchCurrentUser();
          if (res?.data) {
            let {data = {}} = await getUserByToken();
            if (data.id) {
              setUser(data);
              main.currentUser = data;
            }
          }
        } else {
          ElMessage.error(res.msg)
        }
      });
    } else {
      console.log('error')
      return false;
    }
  });
}

// 倒计时
function timeOut(codeText) {
  if (state.code_txt !== codeText) {
    return false
  }
  let time = 60
    state.timeId = setInterval(() => {
    if (time == 0) {
      state.code_txt = codeText
      clearInterval(state.timeId)
      return false;
    }
    state.btnDisabled = false;
    state.code_txt = time + 'S';
    time--;
  }, 1000);
}

// 发送短信验证码
function onSendCode(codeText) {
  if (state.btnDisabled) {
    return ;
  }
  state.btnDisabled = true;
  if (state.code_txt !== codeText) {
    return false
  }
  let {phone, imgCaptcha} = state.ruleForm;

  if (phone.trim() == '') {
    ElMessage({
      message: state.IsChina ? '请输入手机号！' : 'Please enter your mobile phone number',
      type: 'warning',
    })
    state.btnDisabled = false;
    return false;
  } else if (state.IsChina && phone.length !== 11) {
    ElMessage({
      message: '请输入正确的手机号！',
      type: 'warning',
    })
    state.btnDisabled = false;
    return false;
  }
  if (!state.IsChina && state.cc == '') {
    ElMessage({
      message: 'Please select your country code',
      type: 'warning',
    })
    state.btnDisabled = false;
    return;
  }
  if (imgCaptcha.trim() == '') {
    ElMessage({
      message: state.IsChina ? '请输入图片中的验证码！' : 'Please enter the verification code',
      type: 'warning',
    })
    state.btnDisabled = false;
    return;
  }
  let cc = state.IsChina ? '86' : state.cc.replace('+', '')
  let data = {
    cc: cc,
    phone: phone,
    iv: state.iv,
    captcha: imgCaptcha
  }
  timeOut(codeText);
  sendCode(data).then(res => {
    if (res.code !== 0) {
      clearInterval(state.timeId)
      state.code_txt = codeText
      state.btnDisabled = false;
      ElMessage.error(res.msg)
    } else {
      ElMessage({
        message: res.msg,
        type: 'success',
      })
    }
  })
}

// 发送验证码
function onSendEmail(codeText) {
  if (state.btnDisabled) {
    return ;
  }
  state.btnDisabled = true;
  if (state.code_txt !== codeText) {
    return false
  }
  let {email} = state.ruleForm;
  if (email.trim() == '') {
    ElMessage({
      message: 'Please enter your email',
      type: 'warning',
    })
    state.btnDisabled = false
    return false;
  }
  let data = {
    email: email
  }
  timeOut(codeText);
  sendEmail(data).then(res => {
    if (res.code !== 0) {
      ElMessage.error(res.msg);
      state.btnDisabled = false
    } else {
      ElMessage({
        message: res.msg,
        type: 'success',
      })
    }
  })
}

function onCountryCode(IsChina) {
  let arr = [];
  countryCode.map(e => {
    if (IsChina) {
      arr.push({
        value: e.code,
        label: e.cn
      })
    } else {
      arr.push({
        value: e.code,
        label: e.en
      })
    }
  })
  state.options = arr;
}

onMounted(() => {
  onCountryCode(state.IsChina)
  window.onresize = () => {
    setTimeout(() => {
      let deviceName = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? 'phone' : 'pc'
      state.deviceName = deviceName
    }, 10);
  };
})
</script>

<style lang="less" scoped>
.pc {
  .login {
    font-family: Montserrat-Light;

    .bg {
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #000;
      opacity: 0.7;
      z-index: 99;
    }

    &-box {
      width: 530px;
      //height: 450px;
      position: fixed;
      left: 50%;
      top: 50%;
      margin-left: -265px;
      margin-top: -200px;
      background: #fff;
      box-sizing: border-box;
      padding: 40px 50px;
      border-radius: 14px;
      z-index: 99;

      .title {
        font-size: 1.875vw;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 2.7083vw;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
        padding-bottom: 2.0833vw;
      }

      /deep/ .el-input__inner:focus {
        border-color: #000;
      }

      /deep/ .el-select {
        width: 120px;
      }

      .close {
        position: absolute;
        right: 40px;
        top: 40px;
        width: 30px;
        cursor: pointer;
      }

      .subLogin {
        width: 100%;
        height: 60px;
        background: url('@/assets/img/img-10.png') no-repeat;
        background-size: 430px 60px;
        font-size: 16px;
        font-weight: 400;
        line-height: 60px;
        color: #000;
        letter-spacing: 3px;
        text-align: center;
        cursor: pointer;
      }

      /deep/ .el-select {
        display: inline-block;
        width: 80px;
      }

      .tab-change {
        ul {
          display: flex;
          padding-bottom: 1.3021vw;

          li {
            min-width: 40px;
            cursor: pointer;
            font-family: Montserrat-Light;
            font-size: 14px;
            text-align: center;
            font-weight: 300;
            margin-right: 1.5625vw;
            color: #666;

            &.on {
              color: #000000;
              font-weight: 500;
              position: relative;

              &::after {
                content: '';
                width: 1.5625vw;
                height: .1042vw;
                background: #000000;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -0.5208vw;
              }
            }
          }
        }
      }
    }

    /deep/ .el-form-item {
      margin-bottom: 30px;
    }

    /deep/ .el-form-item:last-child {
      margin-bottom: 0;
    }

    /deep/ .el-input {
      height: 40px !important;
      line-height: 40px !important;
    }

    .img-captcha-box {
      display: flex;
      width: 100%;
      justify-content: space-between;

      img {
        max-width: 140px;
        cursor: pointer;
      }

      .el-input {
        width: 260px;
      }
    }

    .captcha-box {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .el-input {
        width: 260px;
      }

      .yzm {
        width: 140px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background: url('@/assets/img/img-08.png') no-repeat;
        background-size: 140px 40px;
        font-size: 14px;
        font-weight: 400;
        color: #000;
        cursor: pointer;

        &.on {
          cursor: no-drop;
        }
      }
    }
  }
}

.Mlogin {
  .login {
    .bg {
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #000;
      opacity: 0.7;
      z-index: 99;
    }

    &-box {
      width: 320px;
      //height: 450px;
      position: fixed;
      left: 50%;
      top: 50%;
      margin-left: -160px;
      margin-top: -200px;
      background: #fff;
      box-sizing: border-box;
      padding: 20px 10px;
      border-radius: 10px;
      z-index: 99;

      &-china {
        // height: 400px;
      }

      .title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 2.7083vw;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
        padding-bottom: 16px;
      }

      /deep/ .el-input__inner:focus {
        border-color: #000;
      }

      /deep/ .el-select {
        width: 120px;
      }

      .close {
        position: absolute;
        right: 3.125vw;
        top: 3.3854vw;
        width: 16px;
        cursor: pointer;
      }

      .subLogin {
        width: 100%;
        height: 50px;
        background: url('@/assets/img/img-10.png') no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 50px;
        color: #000;
        letter-spacing: 3px;
        text-align: center;
        cursor: pointer;
      }

      /deep/ .el-select {
        display: inline-block;
        width: 80px;
      }

      .tab-change {
        ul {
          display: flex;
          padding-bottom: 10px;

          li {
            min-width: 40px;
            cursor: pointer;
            font-family: Montserrat-Light;
            font-size: 14px;
            text-align: center;
            font-weight: 300;
            margin-right: 1.5625vw;
            color: #666;

            &.on {
              color: #000000;
              font-weight: 500;
              position: relative;

              &::after {
                content: '';
                width: 1.5625vw;
                height: .1042vw;
                background: #000000;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -0.5208vw;
              }
            }
          }
        }
      }
    }

    /deep/ .el-form-item {
      margin-bottom: 15px;
    }

    /deep/ .el-form-item:last-child {
      margin-bottom: 0;
    }

    /deep/ .el-input {
      height: 40px !important;
      line-height: 40px !important;
    }

    .img-captcha-box {
      display: flex;
      width: 100%;
      justify-content: space-between;

      img {
        max-width: 110px;
        cursor: pointer;
      }

      .el-input {
        width: 180px;
      }
    }

    .captcha-box {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .el-input {
        width: 180px;
      }

      .yzm {
        width: 100px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background: url('@/assets/img/img-08.png') no-repeat;
        background-size: cover;
        font-size: 14px;
        font-weight: 400;
        color: #000;
        cursor: pointer;

        &.on {
          cursor: no-drop;
        }
      }
    }
  }
}

</style>

<template>
  <div class="pc" v-if="deviceName!=='phone'">
    <table v-if="appointment_data?.length">
      <thead>
      <td>{{ $t('user.record.table1') }}</td>
      <td>{{ $t('user.record.table2') }}</td>
      <td>{{ $t('user.record.table4') }}</td>
      <td>{{ $t('user.record.table3') }}</td>
      <td>{{ $t('user.record.table5') }}</td>
      </thead>
      <tr v-for="(item, n) in appointment_data" :key="n">
        <td>{{ n + 1 }}</td>
        <td>{{ item.user.name }}</td>
        <td>{{ item.num }}</td>
        <td>{{ item.user.phone }}</td>
        <td>{{ item.created_at }}</td>
      </tr>
    </table>
    <Empty v-else type='2' :text="$t('user.record.nodata')" @click="toHome"/>
  </div>
  <div class="phone record-phone" v-else-if="deviceName==='phone'">
    <div v-for="(item, n) in appointment_data" :key="n">
      <div class="list" :class="item.show?'show':'hide1'" @click="hadleActive(n)">
        <div class="item">
          <div>{{$t('user.record.table1')}}</div>
          <div>{{ n + 1 }}</div>
        </div>
        <div class="item">
          <div>{{$t('user.record.table2')}}</div>
          <div>{{ item.user.name }}</div>
        </div>
        <div class="item">
          <div>{{$t('user.record.table3')}}</div>
          <div>{{ item.num }}</div>
        </div>
        <div class="item">
          <div>{{$t('user.record.table4')}}</div>
          <div>{{ item.user.phone }}</div>
        </div>
        <div class="item">
          <div>{{$t('user.record.table5')}}</div>
          <div>{{ item.created_at }}</div>
        </div>
      </div>
    </div>
    <div class="nodata" v-show="showPage" v-if="!appointment_data.length">
      <img src="@/assets/user/no-2.png" alt="">
      <div>
        <P>{{$t('user.home.nodata2')}}</P>
        <P>{{$t('user.home.nodata1')}}</P>
      </div>

<!--      <div class="btn" @click="toHome">{{$t('user.now.book')}}</div>-->
    </div>
  </div>
  <div class="collect" v-else>
    <div v-for="(item, n) in appointment_data" :key="n">
      <div class="item">
        <video poster="" muted autoplay="autoplay" loop="loop">
          <source :src="item.cover" />
        </video>
        <p class="p1">{{item.name}}</p>
        <p class="p2">{{ $t('user.collect.item.name') }}：{{item.chain_name}}</p>
        <p class="p2">token ID：{{item.hash}}</p>
        <p class="p2">{{ $t('user.collect.blockchain_hash') }}：{{item.blockchain_hash}}</p>
      </div>
    </div>

    <div class="nodata" v-show="showPage" v-if="!appointment_data.length">
      <img src="@/assets/user/card.png" alt="" srcset="">
      <P>{{ $t('user.home.nodata1') }}</P>
<!--      <div class="btn" @click="toHome">{{$t('user.now.book')}}</div>-->
    </div>
  </div>
</template>

<script>
import {getUser, userList} from '@/services/person'
import {deviceName} from '@/utils'
import Empty from './empty.vue'
import {Toast} from "vant";

export default {
  components: {
    Empty
  },
  data() {
    return {
      deviceName,
      active: 0,
      appointment_data: [], // 预约2
      invitation_data: [], // 邀请1,
      showPage: false,

    }
  },
  mounted() {
    userList({type: 2}).then(res => {
      // console.log('res', res);
      if(deviceName!=='phone')this.appointment_data = res.data;
      else if(deviceName=='phone'){
        Toast.clear();
        this.showPage = true;
        let list = res.data;
        if (list.length > 0) {
          for (let i in list) {
            if(i == 0){
              list[i].show = true
            } else {
              list[i].show = false
            }
          }
        }
        this.appointment_data = list;
      }
    })
  },
  methods: {
    toHome() {
      router.push('/')
    },
    hadleActive(val) {
      for (let i in this.appointment_data) {
        if(i == val){
          this.appointment_data[i].show = !this.appointment_data[i].show
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.pc {
  .nodata {
    text-align: center;
    color: #fff;
    font-size: 14px;
    padding: 0 10px;
    box-sizing: border-box;
  }
  table {
    width: 100%;
    font-size: 12px;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    box-sizing: content-box;
    border-collapse: collapse;
    thead {
      background-color: #fff;
      text-align: center;
      color: black;
      td {
        padding: 10px;
      }
    }
    tr {
      td {
        padding: 10px;
      }
    }
  }
}
ul {
  display: block;
  position: relative;
  &.ul-info {
    li {
      box-sizing: border-box;
      height: 50px;
      border: 0.5px solid #3B3B3B;
      border-radius: 1px;
      margin-bottom: 10px;
      .li-1 {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
      }
      &.on {
        height: 167px;
        border: 0.5px solid #FFFFFF;
        border-radius: 1px;
        background: none;
      }
      .li-2 {
        padding-top: 10px;
      }
      .li-list {
        display: flex;
        padding: 0 15px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 30px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.phone {
  @media screen and (max-width:768px){
    background: black;
    color: #FFF;
    padding: 0 40px;
    .list {
      border: 1px solid #3B3B3B;
      border-radius: 2px;
      margin-top: 20px;
      .item {
        padding: 0 15px;
        height: 50px;
        font-size: 12px;
      }
    }
    .show {
      height: auto;
    }
    .hide1 {
      height: 50px;
      overflow: hidden;
    }
    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .nodata {
      text-align: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      border: 1px dashed #555;
      width: 100%;
      height: 390px;
      margin: 0 auto;
      font-size: 11px;
      img {
        width: 50px;
      }
    }
    .btn {
      background: url("@/assets/user/img-08.png") no-repeat;
      background-size: contain;
      width: 90px;
      text-align: center;
      color: #000;
      height: 25px;
      line-height: 25px;
    }
  }
}

</style>
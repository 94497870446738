<template>
  <div class="Head" :class={bg:!!props.bg}>
    <header :class="{ active: props.activeIndex !== 0 }">
<!--      <img @click="goHome" src="@/assets/img/logo-fff.png" alt="" srcset=""-->
<!--           v-if="router.currentRoute.value.path == '/metavertu'">-->
      <img @click="goHome" src="@/assets/img/logo.png" alt="" srcset="" >
      <div class="menu">
        <router-link class="tab tab-active" to="/" :class="(router.currentRoute.value.path == '/metavertu')?'tab1':''">
          HOME
        </router-link>
        <router-link class="tab" target="_blank" to="/metavertu"
                     :class="router.currentRoute.value.path == '/metavertu'?'active1':''">METAVERTU
        </router-link>
        <div class="tab" @click="openToVpass">VPASS</div>
      </div>
      <div class="btn-box">
        <div @click="goW" class="manifesto-btn" data-parallax >
          WHITEPAPER
        </div>
        <div @click="go" class="manifesto-btn" data-parallax >
          {{ $t('meta.manifesto.buy') }}
        </div>
        <div class="login" @click="loginDialog" v-if="!main.currentUser.id"
            :class="router.currentRoute.value.path == '/metavertu'?'active1':''">
          {{ $t('Head.w69log') }}
        </div>
        <div class="avatar-box" v-if="main.currentUser.id" @click="showMenu">
          <img :src="`https://republicofvertu.com${isDev ? '/test' : ''}` + main.currentUser.avatar" alt="avatar"
               class="avatar">
          <div class="user-nav" v-if="state.showMe">
            <div class="user-nav-list" @click="goTo(0)">{{ $t('Head.nx8u7x') }}</div>
            <div class="user-nav-list" @click="goTo(1)">{{ $t('Head.rzn6l7') }}</div>
            <div class="user-nav-list" @click="goTo(2)">{{ $t('Head.jmizgm') }}</div>
            <div class="user-nav-list" @click="logout">{{ $t('Head.w69zi8') }}</div>
          </div>
        </div>
      </div>
    </header>
    <login/>
  </div>
</template>

<script setup>
import login from '@/components/login2'
import {userStore} from '@/store/user.ts'
import {onMounted, reactive} from "vue";
import router from '@/router';
import {isDev} from "@/utils/index.js"
import {openToVpass, buyMetavertu} from '@/utils/index'
import {locale} from "@/services"

onMounted(() => {
  if (router.currentRoute.value.path == '/metavertu') {
    console.log(document.querySelector('Head'), 36)
    // document.querySelector('.Head').style.background = '#fff';
  }
})
console.log(router, router.currentRoute, 34)
const state = reactive({
  showMe: false
})
const props = defineProps({
  activeIndex: Number,
  bg: Boolean,
  index: 0
})
const main = userStore()

const loginDialog = () => {
  main.loginDialog = true;
}

const showMenu = () => {
  state.showMe = !state.showMe;
}

function goTo(val) {
  state.showMe = false;
  router.push({
    path: '/user',
    query: {
      type: val
    }
  })
}
const go = ()=>{
  // 买手机
  buyMetavertu()
}
const goW = ()=> {
  if (locale == 'zh') {
    window.open('https://republicofvertu.com/vpass/whitePaper/zh/index.html')
  } else if (locale == 'en') {
    window.open('https://republicofvertu.com/vpass/whitePaper/en/index.html')
  }
}

function logout() {
  router.push('/').then(() => {
    location.reload()
  })
  main.clearCurrentUser()
  state.showMe = false;
}
</script>

<style lang="less" scoped>
.Head {
  font-family: 'Qurany-Bold';
  position: fixed;
  width: 100%;
  z-index: 999;
  &.bg {
    //background-color: rgb(9, 9, 9);
  }
  .user-nav {
    position: absolute;
    left: -40px;
    top: 54px;
    height: 190px;
    background: url('@/assets/img/bg-07.png') no-repeat;
    background-size: contain;
    padding-top: 5px;
    width: 140px;
    border-radius: 4px;
    &-list {
      color: #000;
      line-height: 38px;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 12px;
      cursor: pointer;
    }
  }
  header {
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 0 4.1667vw;
    align-items: center;
    .avatar {
      cursor: pointer;
      width: 50px;
      border-radius: 50%;
      &-box {
        position: relative;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background: url("https://republicofvertu.com/v2appointment/api/img/src?src=/uploads/images/gender_0.png") no-repeat;
        background-size: cover;
      }
    }
    img {
      width: 180px;
    }
    .login {
      color: #000;
      border: 2px solid #000;
      width: 112px;
      height: 40px;
      border-radius: 10px;
      line-height: 44px;
      text-align: center;
      font-size: 22px;
      cursor: pointer;
    }
    .menu {
      display: flex;
      line-height: 1.2;
      justify-content: center;
      .tab {
        color: #666;
        cursor: pointer;
        font-size: 30px;
        font-family: Helvetica;
        letter-spacing: 3px;
        padding: 0 24px;
        font-family: 'Qurany-Bold';
        &-active {
          color: #000000;
        }
        &.tab1 {
          color: #A3A3A3;
        }
        &.active1 {
          color: #fff;
        }
      }
    }
    .btn-box{
      width: 650px;
      display: flex;
      justify-content: right;
    }
    .manifesto-btn {
      color: #000;
      border: 2px solid #000;
      min-width: 112px;
      padding: 0 15px;
      margin-right: 34px;
      height: 40px;
      border-radius: 10px;
      line-height: 44px;
      text-align: center;
      font-size: 22px;
      cursor: pointer;
      animation: loginChange;
      animation-duration: 3s;
      cursor: pointer;
    }
  }
  .active {
    .login {
      color: #FFF;
      border: 2px solid #fff;
      animation: loginChange;
      animation-duration: 3s;
      cursor: pointer;
    }
    
    .manifesto-btn {
      color: #FFF;
      border: 2px solid #fff;
    }
    .menu {
      .tab {
        color: #A3A3A3;
        animation: colorChange;
        animation-duration: 3s;
        &-active {
          color: #fff;
          animation: colorChangeF;
          animation-duration: 3s;
        }
      }
    }
  }
  @keyframes colorChangeF {
    from {
      color: #000;
    }
  }
  @keyframes colorChange {
    from {
      color: #666;
    }
  }
  @keyframes loginChange {
    from {
      color: #000;
      border: 2px solid #000;
    }
  }
}

</style>

import {ElMessage} from 'element-plus'
import {locale} from "@/services";

export const deviceName = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? 'phone' : 'pc'
export const isDev = location.pathname.substring(0, 5) === "/test" || location.hostname === "localhost" || location.hostname.startsWith('192.') || location.hostname.startsWith('127.')

export const objDeepCopy = function (source) {
    var sourceCopy = source instanceof Array ? [] : {};
    for (var item in source) sourceCopy[item] = typeof source[item] === 'object' ? objDeepCopy(source[item]) : source[item];
    return sourceCopy;
}

export const deepCopy = function (source) {
    var sourceCopy = source instanceof Array ? [] : {};
    for (var item in source) {

        sourceCopy[item] = typeof source[item] === 'object' ? objDeepCopy(source[item]) : source[item];
    }
    return sourceCopy;
}

// 立即购买METAVERTU
export function buyMetavertu() {
    if (locale === 'zh') {
        window.open('https://www.vertu.cn/products/metavertu')
    } else {
        console.log('跳转国外官网')
        window.open('https://www.vertu.com/products/metavertu')
    }
}

// 跳转到Vpass官网
export function openToVpass() {
    console.log(deviceName, 34,isDev)
    if (deviceName == 'phone') {
        if (isDev) {
            window.open('http://republicofvertu.com/vpass/m/#/')
        } else {
            console.log('跳转正式环境的vpass')
            window.open('https://republicofvertu.com/vpass/m/')
        }
    } else {
        if (isDev) {
            window.open('http://vpass.republicofvertu.com/')
        } else {
            console.log('跳转正式环境的vpass')
            window.open('https://republicofvertu.com/vpass')
        }
    }

}

/**
 * 过滤空值的属性
 * @param obj
 * @constructor
 */
export const filterData = function (obj) {
    for (var key in obj) {
        if (obj[key] == null || obj[key] === '') {
            delete obj[key]
        }
    }
}

export const formatDate = function (format, date = new Date()) {
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "H+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return format;
}

export const accountTxt = (account) => {
    let str = ''
    if (account && typeof account === 'string') {
        str = account.substring(0, 6)
        str = str + '...'
        str = str + account.substring(account.length - 4)
    }
    return str
}

/**
 * 一键粘贴
 * @param  {String} id [需要粘贴的内容]
 * @param  {String} attr [需要 copy 的属性，默认是 innerText，主要用途例如赋值 a 标签上的 href 链接]
 *
 * range + selection
 *
 * 1.创建一个 range
 * 2.把内容放入 range
 * 3.把 range 放入 selection
 *
 * 注意：参数 attr 不能是自定义属性
 * 注意：对于 user-select: none 的元素无效
 * 注意：当 id 为 false 且 attr 不会空，会直接复制 attr 的内容
 */
export const copyTxt = function (attr, id) {
    let target = null;

    if (attr) {
        target = document.createElement('div');
        target.id = 'tempTarget';
        target.style.opacity = '0';
        if (id) {
            let curNode = document.querySelector('#' + id);
            target.innerText = curNode[attr];
        } else {
            target.innerText = attr;
        }
        document.body.appendChild(target);
    } else {
        target = document.querySelector('#' + id);
    }

    try {
        let range = document.createRange();
        range.selectNode(target);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        // console.log('Copy succeeded');
        ElMessage({
            message: localStorage.getItem('lang') == 'en' ? 'copy success' : 'Copy succeeded',
            type: 'success',
            duration: 5 * 1000
        })
        // this.$message.success(localStorage.getItem('lang') == 'en' ? 'copy success' : 'Copy succeeded');
    } catch (e) {
        ElMessage({
            message: localStorage.getItem('lang') == 'en' ? 'copy fail' : 'Copy failed',
            type: 'error',
            duration: 5 * 1000
        })
        // console.log('copy failed');
        // this.$message.error(localStorage.getItem('lang') == 'en' ? 'copy fail' : 'copy failed');
    }

    if (attr) {
        // remove temp target
        target.parentElement.removeChild(target);
    }
}

import { get, post } from "./request";
 
export interface ICurrentUser {
    id: number;
    token: string;
    activity_sn: string;
    appointment_num: number;
    avatar: string;
    cc: string;
    created_at: string;
    email: string;
    from_type: number;
    gender: number;
    invitation_code: string;
    invitation_url: string;
    name: string;
    phone: string;
    plat_user_id: number;
    wallet_address: string;
}

export const sendCode = (params = {}) => {
    return get("/sms/sendCode", { params });
};

export const login = (data = {}) => {
    return post("/user/user/login", data);
};

export const sendEmail = (params = {}) => {
    return get("/email/sendEmail", { params });
};

export const getUserByToken = () => {
    return get("/user/user/info");
};

// 获取验证码图片码
export function getCaptcha() {
    return get("/captcha/build");
}
import {createRouter, createWebHashHistory} from "vue-router";
import Index from "./pages/index.vue";
import Metaphone from "./pages/metaphone/index.vue";
import User from "./pages/user/index.vue";
import PhoneUser from "./pages/user/index";
import Republic from "./pages/republic/index.vue";

const routes = [
    { path: "/", component: Republic },
    { path: "/metavertu", component: Metaphone },
    { path: "/republic", component: Republic },
    {path: '/user', component: User},
    // {path: '/user', component: deviceName=='phone'?PhoneUser:User},
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;

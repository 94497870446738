import {get,post} from '@/services/request'

// 获取用户信息
export function getUser() {
    return get("/user/user/info");
}
// 手机验证码
export function sendCode(params:{}) {
    return get("/sms/sendCode", {...params});
}
// Email验证码
export function sendEmail(params:{}) {
    return get("/email/sendEmail", {...params});
}

// 预约邀请信息 预约type：2 邀请type：1
export function userList(params:{}) {
    return get("/user/appointment/list", {...params});
}

// 藏品列表
export function giftList() {
    return get("/user/gift/list", );
}
// 登录
export function login(data:{}) {
    return post('/user/user/login', data)
}
// 判断国内国外
export function countryByIp() {
    return get("/ip/countryByIp");
}

<template>
    <div class="line pc" v-for="(item) in data" :key="item.i">
        <div class="item" v-for="(child,ci) in item.children" :key="ci" :style="{ width: width }">
            <div class="no">
                {{ child.no }} <img src="@/assets/user/no.png" alt="">
            </div>
            <img class="head" :src="child.user?.avatar || defaultImg" alt="">
            <div class="name">{{child.user?.name || '- -'}}</div>
        </div>
    </div>
</template>

<script>
import defaultImg from '@/assets/user/user-img.png'
export default {
    props: {
        count: {
            type: Number,
            default() {
                return 3
            }
        },
        list: []
    },
    data() {
        return {
            defaultImg
        }
    },
    computed: {
        width() {
            let gapWidth = (this.count - 1) * 40 + this.count * 2
            return `calc((100% - ${gapWidth}px) /${this.count})`
        },
        data() {
            return new Array(Math.ceil(this.list.length / this.count)).fill({}).map((m, i) => {
                return {
                    i: i + 1,
                    children: this.list.slice(i * this.count, this.count + i * this.count)
                        .map((c, ci) => {
                            let no = (i) * this.count + (ci + 1)
                            return { ...c, no: no > 10 ? no : `0${no}` }
                        }),
                };
            })
        }
    }
}
</script>

<style lang="less" scoped>
.line.pc {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;

    .item {
        // width: calc((100% - 46px) /3);
        border-radius: 10px;
        border: 1px dashed #D1D1D1;
        padding: 15px 0 10px 0;
        position: relative;
        height: 80px;
        font-size: 11px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        &:last-of-type::after {
            display: none;
        }

        .no {
            display: flex;
            align-items: center;
            position: absolute;
            top: 2px;
            left: 8px;
            font-weight: bold;
            font-size: 16px;

            img {
                width: 6px;
                height: 10px;
                margin-left: 3px;
            }
        }

        .head {
            width: 34px;
            height: 34px;
            border-radius: 100%;
            border: 3px solid #fff;
            box-shadow: 0 0 8px 5px rgba(255, 255, 255, 0.5);
        }
    }

    &:last-of-type {
        margin-bottom: 0;

        .item:last-of-type:after,
        .item:last-of-type::before {
            display: none !important;
        }
    }

    &:nth-of-type(odd) {
        .item:not(:last-of-type):after {
            content: '';
            display: block;
            width: 20px;
            height: 1px;
            border-top: 1px dashed #fff;
            position: absolute;
            top: 50%;
            right: -30px;
            transform: translateY(-50%);
        }

        &:not(:last-of-type) {
            .item:last-of-type:after {
                content: '';
                display: block;
                width: 1px;
                height: 20px;
                border-left: 1px dashed #fff;
                position: absolute;
                left: 50%;
                bottom: -30px;
                transform: translateX(-50%);
            }
        }
    }

    &:nth-of-type(even) {
        flex-direction: row-reverse;

        // 奇
        .item::after {
            content: '';
            display: block;
            width: 20px;
            height: 1px;
            border-top: 1px dashed #fff;
            position: absolute;
            top: 50%;
            left: -30px;
            transform: translateY(-50%);
        }

        .item:nth-of-type(4) {
            &::after {
                display: none;
            }

            &::before {
                content: '';
                display: block;
                width: 1px;
                height: 20px;
                border-left: 1px dashed #fff;
                position: absolute;
                left: 50%;
                bottom: -30px;
                transform: translateX(-50%);
            }
        }
    }
}
</style>